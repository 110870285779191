import i18n from '../../../i18n/i18n'

export enum Language {
  Spanish = 'es',
  English = 'en',
}

export const optionLanguage = (): Record<Language, string> => ({
  [Language.Spanish]: i18n.t(Language.Spanish),
  [Language.English]: i18n.t(Language.English),
})


export enum PatientLanguage {
  //TODO ADD new languages
  Spanish = 'es',
  English = 'en',
  French = 'fr',
  Portuguese = 'pr',
}

export const optionPatientLanguage = (): Record<PatientLanguage, string> => ({
  [PatientLanguage.Spanish]: i18n.t(PatientLanguage.Spanish),
  [PatientLanguage.English]: i18n.t(PatientLanguage.English),
  [PatientLanguage.French]: i18n.t(PatientLanguage.French),
  [PatientLanguage.Portuguese]: i18n.t(PatientLanguage.Portuguese)
})