// @ts-nocheck
import React, {ChangeEvent, useEffect, useState} from 'react'
import styles from './ModalPreview.module.css'
import {Divider, Modal, Typography} from '@mui/material'
import {COLOR_PRIMARY} from 'routes/color-constants'
import {useStyles} from 'features/matchings/Matching.styles'
import {useTranslation} from 'react-i18next'
import {
    Box,
    Button,
    Checkbox,
    createStyles,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    Theme,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import {Legend} from 'components/legend/Legend'
import {customOrder, RolePGDTest, rolePgdTest} from 'modules/users/enums/RoleType'
import {validColors} from 'modules/analysis/enum/ColorEnum'
import {getProjectContainer} from '../../../container/project-module'
import {ProjectService} from '../../../modules/projects/services/ProjectService'
import {PROJECT_SERVICE_KEY} from '../../../modules/projects'
import {ProjectAnalysis} from '../../../modules/projects/models/Project'
import {getAuthContainer} from '../../../container/auth-modules'
import {AUTH_SERVICE_KEY} from '../../../modules/auth'
import {AuthService} from '../../../modules/auth/services/AuthService'
import {FormAction, FormActions} from '../../../common/utils/form-generation'
import {commonStyles} from '../../../common/styles/Styles'
import {MutationsPositionService} from '../../../modules/mutationsPosition/services/MutationsPositionService'
import {MUTATIONS_POSITION_SERVICE_KEY} from '../../../modules/mutationsPosition'
import {getMutationsPositionContainer} from '../../../container/mutationsPositions-module'
import {MutationsPositionDTO} from '../../../modules/mutationsPosition/models/MutationsPosition'
import {Individuals} from '../../../modules/individuals/enums/Individuals'
import {v4 as uuidv4} from 'uuid'
import {getWordContainer} from '../../../container/word-module'
import {WordService} from '../../../modules/word/services/WordService'
import {WORD_SERVICE_KEY} from '../../../modules/word'
import {WordDTO} from '../../../modules/word/models/Word'
import {makeStyles} from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'

export type ModalPreviewProps = {
    project?: ProjectAnalysis
    setProject: (s: ProjectAnalysis) => void
    data: any
    modalPreviewOpened: boolean
    setModalPreviewOpened: (b: boolean) => void
    currentStep?: number
    runParentsSnipetsProcess?: () => void
}

const usaStyles = makeStyles((theme: Theme) =>
    createStyles({
        containerAllele: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            width: '35%',
            height: 'auto',
            padding: '30px',
            minWidth: '400px',
            borderRadius: '8px',
            paddingTop: '21px',
            justifyContent: 'center',
            display: 'flex',
            alignContent: 'center',
            flexDirection: 'column',
        },
        alleleTitle: {
            fontWeight: 'bold',
            fontSize: '15px',
            marginTop: '1.5%',
            alignSelf: 'center',
        },
        alleleDivs: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
            alignSelf: 'center',
            marginTop: '5.5%',
        },
        alleleCloseIcon: {
            position: 'absolute',
            top: '6px',
            right: '9px',
            cursor: 'pointer',
        },
        formControl: {
            minWidth: 120,
        },
        toggle: {
            width: 68,
            height: 47,
            pading: 0,
            '& .MuiSwitch-thumb': {
                boxSizing: 'border-box',
                width: 30,
                height: 30,
            },
            '& .MuiSwitch-track': {
                borderRadius: 47 / 2,
            },
        },
    })
)

const projectService = getProjectContainer().get<ProjectService>(PROJECT_SERVICE_KEY)
const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)
const mutationService = getMutationsPositionContainer().get<MutationsPositionService>(
    MUTATIONS_POSITION_SERVICE_KEY
)
const wordService = getWordContainer().get<WordService>(WORD_SERVICE_KEY)

export function ModalPreview(props: ModalPreviewProps): JSX.Element {
    const [showScrollbar, setShowScrollbar] = useState(true)
    const classes = useStyles({color: COLOR_PRIMARY})
    const {t} = useTranslation()
    const [motherInfo, setMotherInfo] = useState<boolean>(true)
    const [fatherInfo, setFatherInfo] = useState<boolean>(true)
    const [noInfo, setNoInfo] = useState<boolean>(false)
    const [semiInfo, setSemiInfo] = useState<boolean>(false)
    const [highlightKeys, setHighlightkeys] = useState<boolean>(true)
    const [qualityCoverage, setQualityCoverage] = useState<boolean>(false)
    const [alleleModal, setAlleleModal] = useState<boolean>(false)
    const [currentPosition, setCurrentPosition] = useState<number>()
    const [currentAIndex, setCurrentAIndex] = useState<number>()
    const [data2, setData2] = useState<any>(props.data)
    const styles2 = commonStyles()
    const [project, setProject] = useState<ProjectAnalysis | undefined>(props.project)
    const [selectedOption, setSelectedOption] = useState('')
    const [modalAddMutation, setModalAddMutation] = useState<boolean>(false)
    const [mutationToAdd, setMutationToAdd] = useState<MutationsPositionDTO>()
    const [mutationChanges, setMutationChanges] = useState<boolean>(false)
    const [saveMutationModal, setSaveMutationModal] = useState<boolean>(false)
    const [changes, setChanges] = useState<boolean>(false)
    const [changesModal, setChangesModal] = useState<boolean>(false)
    const [currentIndividual, setCurrentIndividual] = useState<any>({})
    const [wordsChanges, setWordsChanges] = useState<WordDTO[]>([])

    const options = [
        {value: '#e8d863', label: t('yellowM')},
        {value: '#09d6e8', label: t('blueM')},
        {value: '#ed6afa', label: t('pinkM')},
        {value: '#03de6c', label: t('greenM')},
        {value: '#ff7b54', label: t('orangeM')},
        {value: '#ddeafb', label: t('whiteM')},
    ]

    const openModalAddMutation = () => {
        setModalAddMutation(true)
    }

    const closeModalAddMutation = () => {
        setModalAddMutation(false)
    }

    const addMutation = (e: any) => {
        e.preventDefault()
        const existingMutation = data2.resultTableData.find(
            (item: any) => item.position === mutationToAdd?.position
        )
        if (existingMutation) {
            existingMutation.individualType = 'MutationMarked'
            let payload = {
                projectID: props.project?.id,
                data: existingMutation,
            }
            mutationService.updateMarkedPositions(payload).subscribe((res) => {
                if (res) {
                    closeSaveMutationModal()
                    props.setModalPreviewOpened(false)
                    props.runParentsSnipetsProcess && props.runParentsSnipetsProcess()
                }
            })
        } else {
            mutationService.add(mutationToAdd).subscribe((res) => {
            })
            closeModalAddMutation()
            props.runParentsSnipetsProcess && props.runParentsSnipetsProcess()
        }
    }

    const handleMutation = (key: string, value: any) => {
        let parts = value.split(':')
        if (parts.length != 2) {
            return
        }
        let newMutation: MutationsPositionDTO = {
            id: uuidv4(),
            position: parseInt(parts[1]),
            chromosome: parts[0],
            role: Individuals.UNKNOWN,
            embryo: 0,
            a1Word: '',
            a2Word: '',
            a1Color: '',
            a2Color: '',
            projectID: props.project?.id || '',
        }

        setMutationToAdd(newMutation)
    }

    const handleMouseEnter = () => {
        setShowScrollbar(true)
    }

    const handleMouseLeave = () => {
        setShowScrollbar(true)
    }

    const subtractLight = function (color: any, amount: any) {
        let cc = parseInt(color, 16) - amount
        let c = cc < 0 ? 0 : cc
        //@ts-ignore
        c = c.toString(16).length > 1 ? c.toString(16) : `0${c.toString(16)}`
        return c
    }

    const darken = (color: any, amount: any) => {
        color = color.indexOf('#') >= 0 ? color.substring(1, color.length) : color
        //@ts-ignore
        amount = parseInt((255 * amount) / 100)
        return (color = `#${subtractLight(color.substring(0, 2), amount)}${subtractLight(
            color.substring(2, 4),
            amount
        )}${subtractLight(color.substring(4, 6), amount)}`)
    }

    const getDataComponentFiltered = (data: any) => {
        switch (true) {
            case data?.individualType == 'mother_info' && !motherInfo:
                return ''
            case data?.individualType == 'father_info' && !fatherInfo:
                return ''
            case data?.individualType == 'no_info' && !noInfo:
                return ''
            case data?.individualType == 'semi_info' && !semiInfo:
                return ''
            case data?.individualType == '':
                return ''
            default:
                return getDataComponent(data, data?.individualType)
        }
    }

    const actionsDelete: FormAction[] = [
        {
            label: t('close'),
            handleAction: closeModalAddMutation,
        },
    ]

    const onPress = (aindex: number, position: number, indexIndividual: number, ind: any) => {
        setAlleleModal(true)
        setCurrentPosition(position)
        setCurrentAIndex(aindex)
        setCurrentIndividual(ind)
    }

    const handleChangeColor = (newColor: string) => {
        let row = data2.resultTableData.filter((r: any) => r.position == currentPosition)

        if (currentAIndex === 1) {
            currentIndividual.a1.color = newColor
        } else {
            currentIndividual.a2.color = newColor
        }

        if (row[0].individualType === 'MutationAdded') {
            setMutationChanges(true)
        } else {
            if (currentAIndex === 1) {
                let wordToPush: WordDTO = {
                    id: currentIndividual.a1.id,
                    word: currentIndividual.a1.word,
                    color: newColor,
                    key: currentIndividual.a1.key,
                }

                let array = wordsChanges ? [...wordsChanges] : []
                array.push(wordToPush)
                setWordsChanges(array)
            } else if (currentAIndex === 2) {
                let wordToPush: WordDTO = {
                    id: currentIndividual.a2.id,
                    word: currentIndividual.a2.word,
                    color: newColor,
                    key: currentIndividual.a2.key,
                }

                let array = wordsChanges ? [...wordsChanges] : []
                array.push(wordToPush)
                setWordsChanges(array)
            }
            setChanges(true)
        }

        setAlleleModal(false)
        setShowScrollbar(true)
        setSelectedOption('')
    }

    const allowedChars = new Set(['C', 'T', 'G', 'A'])

    const handleInput = (e: any, aindex: number, indexIndividual: number, ind: any, data: any) => {
        const inputText = e.target.innerText

        if (inputText.length === 1 && allowedChars.has(inputText)) {
            e.target.dataset.valid = 'true'
        } else {
            e.target.innerText = '' // Reset to empty if invalid
            e.target.dataset.valid = 'false'
        }
    }

    const handleKeyDown = (e: any, aindex: number, ind: any) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            const inputText = e.target.innerText

            if (e.target.dataset.valid === 'true') {
                updateMutation(aindex, inputText, ind)
                e.target.blur()
            } else {
                e.target.innerText = '' // Reset to empty if invalid
            }
        }
    }

    const updateMutation = (aindex: any, inputText: any, ind: any) => {
        if (aindex === 1) {
            ind.a1.word = inputText
        } else {
            ind.a2.word = inputText
        }

        setMutationChanges(true)
    }

    function filterUniqueRoles(individual: any[]) {
        const uniqueRoles = new Set()
        return individual?.filter((item) => {
            if (!uniqueRoles.has(item.role) || item.role == RolePGDTest.Embryo) {
                uniqueRoles.add(item.role)
                return true
            }
            return false
        })
    }

    function filterUniqueRolesWithoutFemaleAndMale(individual: any[]) {
        const uniqueRoles = new Set()
        return individual?.filter((item) => {
            if (!uniqueRoles.has(item.role) || item.role == RolePGDTest.Embryo) {
                uniqueRoles.add(item.role)
                return true
            }
            return false
        })
    }

    const [selectedMutations, setSelectedMutations] = useState<any[]>([])
    const [markedMutation, setMarkedMutation] = useState<boolean>(false)

    const handleCheckboxChange = (item: any) => {
        setSelectedMutations((prevSelectedItems) => {
            if (prevSelectedItems.find((selectedItem) => selectedItem.position === item.position)) {
                return prevSelectedItems.filter((selectedItem) => selectedItem.position !== item.position)
            } else {
                return [...prevSelectedItems, item]
            }
        })
    }

    useEffect(() => {
        if (selectedMutations.length > 0) {
            setMarkedMutation(true)
        } else {
            setMarkedMutation(false)
        }
    }, [selectedMutations])

    const getData = (ind: any, indexIndividual: number, data: any, individualType: string) => {
        if (!(ind.role == RolePGDTest.Embryo) && isEmbryoHidden3(ind.role)) return null
        if (ind.role == RolePGDTest.Embryo && isEmbryoHidden2(ind.name)) return null

        return (
            <div
                style={{
                    backgroundColor:
                        individualType === 'MutationAdded' || individualType === 'MutationMarked'
                            ? '#000000'
                            : '',
                    width: qualityCoverage ? '118.2px' : '85.5px',
                    position: 'sticky',
                }}>
                <div className={styles.wordContainer2}>
                    <div
                        className={qualityCoverage ? styles.subContainerQC : styles.subContainer}>
                        <div
                            className={styles.a2Word}
                            key="a2Word"
                            contentEditable={ind.a1.word === ''}
                            onInput={(e) => handleInput(e, data?.position, 2, indexIndividual, ind)}
                            onKeyDown={(e) => handleKeyDown(e, 1, ind)}
                            onClick={() => ind.a1.word && onPress(1, data.position, indexIndividual, ind)}
                            style={{
                                backgroundColor:
                                    individualType === 'MutationAdded'
                                        ? ind.a1.color
                                            ? ind.a1.color
                                            : 'rgb(221, 234, 251)'
                                        : ind.a1.key && highlightKeys && validColors.includes(ind.a1.color)
                                            ? darken(ind.a1.color, 20)
                                            : ind.a1.color
                                                ? ind.a1.color
                                                : '#323232',
                                border: ind.a1.word && `3px solid ${ind.a1.color}`,
                                borderRight: ind.a1.word && `3px solid ${ind.a1.color}`,
                                cursor: 'pointer',
                            }}>
                            {ind.a1.word || '\u200B'}
                        </div>

                        <div
                            key="a1Word"
                            onClick={() => ind.a2.word && onPress(2, data.position, indexIndividual, ind)}
                            className={styles.a2Word}
                            contentEditable={ind.a2.word == ''}
                            onInput={(e) => handleInput(e, data?.position, 2, indexIndividual, ind)}
                            onKeyDown={(e) => handleKeyDown(e, 2, ind)}
                            style={{
                                backgroundColor:
                                    individualType === 'MutationAdded'
                                        ? ind.a2.color
                                            ? ind.a2.color
                                            : 'rgb(221, 234, 251)'
                                        : ind.a2.key && highlightKeys && validColors.includes(ind.a2.color)
                                            ? darken(ind.a2.color, 20)
                                            : ind.a2.color
                                                ? ind.a2.color
                                                : '#323232',
                                border: ind.a2.word && `3px solid ${ind.a2.color}`,
                                borderRight:
                                    ind.a2.color != '#999999'
                                        ? ind.a2.word && `3px solid ${ind.a2.color}`
                                        : '0.5px solid grey',
                                cursor: 'pointer',
                                borderLeft:
                                    ind.a2.key && highlightKeys
                                        ? ind.a2.word && `3px solid ${ind.a2.color}`
                                        : ind.a2.color && '0.5px solid grey',
                            }}>
                            {ind.a2.word || '\u200B'}
                        </div>
                    </div>
                    {qualityCoverage && (
                        <div
                            className={qualityCoverage ? styles.subContainerQC : styles.subContainer}>
                            <div
                                className={styles.quacoR}
                                style={{
                                    backgroundColor: ind.quality > 20 ? 'rgb(182, 197, 204)' : 'rgb(153, 153, 153)',
                                    border: `3px solid ${
                                        ind.quality > 20 ? 'rgb(182, 197, 204)' : 'rgb(153, 153, 153)'
                                    }`,
                                    borderRight: '1px solid grey',
                                }}>
                                {ind.quality}
                            </div>
                            <div
                                className={styles.quaco}
                                style={{
                                    backgroundColor: ind.coverage > 10 ? 'rgb(182, 197, 204)' : 'rgb(153, 153, 153)',
                                    border: `3px solid ${
                                        ind.coverage > 10 ? 'rgb(182, 197, 204)' : 'rgb(153, 153, 153)'
                                    }`,
                                }}>
                                {ind.coverage}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    const getDataComponent = (data, individualType) => {
        return (
            <div
                className={
                    selectedMutations.find((item) => item.position === data.position) ||
                    data.individualType === 'MutationMarked'
                        ? styles.dataContainerCondition
                        : styles.dataContainer
                }>
                <Checkbox
                    size="small"
                    className={styles.checkbox}
                    disabled={individualType === 'MutationAdded'}
                    checked={
                        individualType === 'MutationMarked' ||
                        selectedMutations.find((selectedItem) => selectedItem.position === data.position)
                    }
                    onChange={() => handleCheckboxChange(data)}
                />
                <div className={styles.dataContainer2}>
                    <div className={styles.dataContainer5}>
                        <div className={styles.chromDiv2}>{data.chromosoma}</div>
                        <div className={styles.dataContainerBorder}/>
                        <div className={styles.posDiv2}>{data.position}</div>
                    </div>
                </div>
                {filterUniqueRolesWithoutFemaleAndMale(data.individual)
                    ?.sort((a, b) => customOrder[a.role] - customOrder[b.role])
                    ?.map((ind, indexIndividual) => (
                        <div
                            className={
                                ind.role === RolePGDTest.Female
                                    ? styles.fixed1
                                    : ind.role === RolePGDTest.Male
                                        ? qualityCoverage
                                            ? styles.fixed2WithQualityCoverage
                                            : styles.fixed2
                                        : styles.withoutSpaces
                            }

                            key={indexIndividual}>
                            {getData(ind, indexIndividual, data, individualType)}
                        </div>
                    ))}
            </div>
        )
    }

    const handleInfoAnalizedByChangeProject = () => {
        const loggedUser = authService.get()
        let name = loggedUser.firstName + ' ' + loggedUser.lastName
        //getDateTime on string
        let date = new Date().toLocaleString()
        let analizeBy = name + ' ' + date
        if (project) {
            const updatedProject = {
                ...project,
                infoAnalizedBy: project.infoAnalizedBy === '' ? analizeBy : '',
            }
            projectService.update(updatedProject)
            setProject(updatedProject)
            props.setProject(updatedProject)
        }
    }

    const handleChangeInfoValidate = () => {
        const loggedUser = authService.get()
        let name = loggedUser.firstName + ' ' + loggedUser.lastName
        //getDateTime on string
        let date = new Date().toLocaleString()
        let validateBy = name + ' ' + date
        if (project) {
            const updatedProject = {
                ...project,
                infoValidateBy: project.infoValidateBy === '' ? validateBy : '',
            }
            projectService.update(updatedProject)
            setProject(updatedProject)
            props.setProject(updatedProject)
        }
    }

    const handleEmbryoAnalizedByChangeProject = () => {
        const loggedUser = authService.get()
        let name = loggedUser.firstName + ' ' + loggedUser.lastName
        //getDateTime on string
        let date = new Date().toLocaleString()
        let embryoAnalizedBy = name + ' ' + date
        if (project) {
            const updatedProject = {
                ...project,
                embryoAnalizedBy: project.embryoAnalizedBy === '' ? embryoAnalizedBy : '',
            }
            projectService.update(updatedProject)
            setProject(updatedProject)
            props.setProject(updatedProject)
        }
    }

    const handleChangeEmbryoValidate = () => {
        const loggedUser = authService.get()
        let name = loggedUser.firstName + ' ' + loggedUser.lastName
        //getDateTime on string
        let date = new Date().toLocaleString()
        let embryoValidateBy = name + ' ' + date
        if (project) {
            const updatedProject = {
                ...project,
                embryoValidateBy: project.embryoValidateBy === '' ? embryoValidateBy : '',
            }
            projectService.update(updatedProject)
            setProject(updatedProject)
            props.setProject(updatedProject)
        }
    }

    const openSaveMutationModal = () => {
        mutationChanges && setSaveMutationModal(true)
        changes && setChangesModal(true)
        //markedMutation && setSaveMutationModal(true)
        props.setModalPreviewOpened(false)
    }

    const closeSaveMutationModal = () => {
        mutationChanges && setSaveMutationModal(false)
    }

    const saveMutationsAndClose = () => {
        if (mutationChanges) {
            let mutationsAdded = data2.resultTableData.filter(
                (r: any) => r.individualType === 'MutationAdded'
            )

            // Estructura el objeto con projectID y data
            let payload = {
                projectID: props.project?.id,
                data: mutationsAdded,
            }

            // Envía el payload al backend
            mutationService.update(payload).subscribe((res) => {
                if (res) {
                    closeSaveMutationModal()
                    props.setModalPreviewOpened(false)
                    props.runParentsSnipetsProcess && props.runParentsSnipetsProcess()
                }
            })
        } /* else if (markedMutation) {
            selectedMutations.forEach((indResult) => {
                indResult.individualType = 'MutationMarked'
                let payload = {
                    projectID: props.project?.id,
                    data: indResult,
                }
                mutationService.updateMarkedPositions(payload).subscribe((res) => {
                    if (res) {
                        closeSaveMutationModal()
                        props.setModalPreviewOpened(false)
                        props.runParentsSnipetsProcess && props.runParentsSnipetsProcess()
                    }
                })
            })
        } */ else {
            closeSaveMutationModal()
            props.setModalPreviewOpened(false)
        }
    }

    const closeChangesModal = () => {
        changes && setChangesModal(false)
    }

    const saveChanges = () => {
        /*TODO
         * Coger words cambiadas y updatearlas en el backend
         * */
        wordsChanges.forEach((word) => {
            wordService.update(word).subscribe(() => {
            })
        })
        closeChangesModal()
    }

    const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
        const selectedColor = event.target.value as string
        setSelectedOption(selectedColor)
        handleChangeColor(selectedColor)
    }

    const styles3 = usaStyles()

    const handleChangeSeeEmbryo = (embryo: any) => {
        let aux = {...data2}
        let auxPreprocessed = data2?.preProcessedData.map((pd: any) =>
            pd.name === embryo.name ? {...pd, seeEmbryo: !pd.seeEmbryo} : pd
        )

        aux.preProcessedData = auxPreprocessed
        setData2(aux)
    }

    const isEmbryoHidden = (name: string) => {
        let ret = false
        let embs = data2.preProcessedData.filter((d) => d.name == name)

        if (embs?.length > 0) {
            ret = embs[0].seeEmbryo
        }
        return !ret
    }

    const isEmbryoHidden3 = (role: string) => {
        let ret = false
        let embs = data2.preProcessedData.filter((d) => d.role == role)

        if (embs?.length > 0) {
            ret = embs[0].seeEmbryo
        }
        return !ret
    }

    const isEmbryoHidden2 = (name: string) => {
        let ret = false
        let embs = data2.preProcessedData.filter((d) => d.sampleName == name)

        if (embs?.length > 0) {
            ret = embs[0].seeEmbryo
        }
        return !ret
    }

    const renderHeader = (data) => {
        const role = rolePgdTest()[data?.role]
        const isEmbryo = role === 'embryo'
        const roleName = isEmbryo ? data.sampleName : role

        if (isEmbryoHidden(data.name)) return null

        let headerClass =
            data.role === RolePGDTest.Female
                ? qualityCoverage
                    ? styles.headerFixed1QualityCoverage
                    : styles.headerFixed1
                : data.role === RolePGDTest.Male
                    ? qualityCoverage
                        ? styles.headerFixed2QualityCoverage
                        : styles.headerFixed2
                    : qualityCoverage
                        ? styles.headerDivQualityCoverage
                        : styles.headerDiv

        if (props.currentStep == '1') {
            console.log('entro')
            headerClass =
                data.role === RolePGDTest.Female
                    ? qualityCoverage
                        ? styles.headerFixed1QualityCoverageStep1
                        : styles.headerFixed1
                    : data.role === RolePGDTest.Male
                        ? qualityCoverage
                            ? styles.headerFixed2QualityCoverageStep1
                            : styles.headerFixed2
                        : qualityCoverage
                            ? styles.headerDivQualityCoverageStep1
                            : styles.headerDiv
        }

        return (
            <div className={headerClass}>
                <div className={styles.roleNameDiv}>{roleName}</div>
                <div className={styles.pContainer0}>
                    <div className={styles.pContainer1}>
                        <div className={qualityCoverage ? styles.subContainerQC : styles.subContainer}>
                            <div className={styles.p1Div}>P1</div>
                            <div className={styles.p2Div}>P2</div>
                        </div>
                        {qualityCoverage && (
                            <div className={qualityCoverage ? styles.subContainerQC : styles.subContainer}>
                                <div className={styles.pQu}>{t('qu')}</div>
                                <div className={styles.pQu}>{t('co')}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <Modal open={props.modalPreviewOpened} onClose={() => props.setModalPreviewOpened(false)}>
                <div className={styles.container}>
                    <Typography variant={'h5'} align="left" style={{fontWeight: 'bold'}}>
                        {t('preview')}
                    </Typography>
                    <Divider/>
                    <div>
                        <div className={styles.headerColumn}>
                            <div className={styles.legendDiv}>
                                <Legend/>
                            </div>
                            <div className={styles.divFilters}>
                                <div className={styles.filterColumn}>
                                    <FormControlLabel
                                        className={motherInfo ? styles.toggleOn : styles.toggleOff}
                                        control={
                                            <Switch
                                                className={styles3.toggle}
                                                checked={motherInfo}
                                                onChange={() => setMotherInfo(!motherInfo)}
                                                color="primary"
                                                style={{color: '#FAFAFA'}}
                                            />
                                        }
                                        key={5}
                                        label={'MOTHER INFO'}
                                        style={{marginLeft: '0.2%'}}
                                    />
                                    <FormControlLabel
                                        className={fatherInfo ? styles.toggleOn : styles.toggleOff}
                                        control={
                                            <Switch
                                                className={styles3.toggle}
                                                checked={fatherInfo}
                                                onChange={() => setFatherInfo(!fatherInfo)}
                                                color="primary"
                                                style={{color: '#FAFAFA'}}
                                            />
                                        }
                                        key={5}
                                        label={'FATHER INFO'}
                                        style={{marginLeft: '0.2%'}}
                                    />
                                </div>
                                <div className={styles.filterColumn2}>
                                    <FormControlLabel
                                        className={noInfo ? styles.toggleOn : styles.toggleOff}
                                        control={
                                            <Switch
                                                className={styles3.toggle}
                                                checked={noInfo}
                                                onChange={() => setNoInfo(!noInfo)}
                                                color="primary"
                                                style={{color: '#FAFAFA'}}
                                            />
                                        }
                                        key={5}
                                        label={'NO INFO'}
                                        style={{marginLeft: '0.2%'}}
                                    />
                                    <FormControlLabel
                                        className={semiInfo ? styles.toggleOn : styles.toggleOff}
                                        control={
                                            <Switch
                                                className={styles3.toggle}
                                                checked={semiInfo}
                                                onChange={() => setSemiInfo(!semiInfo)}
                                                color="primary"
                                                style={{color: '#FAFAFA'}}
                                            />
                                        }
                                        key={5}
                                        label={'SEMI INFO'}
                                        style={{marginLeft: '0.2%'}}
                                    />
                                </div>
                                <div className={styles.filterColumn3}>
                                    <FormControlLabel
                                        className={highlightKeys ? styles.toggleOn : styles.toggleOff}
                                        control={
                                            <Switch
                                                className={styles3.toggle}
                                                checked={highlightKeys}
                                                onChange={() => setHighlightkeys(!highlightKeys)}
                                                color="primary"
                                                style={{color: '#FAFAFA'}}
                                            />
                                        }
                                        key={5}
                                        label={'HIGHLIGHT KEYS'}
                                        style={{marginLeft: '0.2%'}}
                                    />
                                    <FormControlLabel
                                        className={qualityCoverage ? styles.toggleOn : styles.toggleOff}
                                        control={
                                            <Switch
                                                className={styles3.toggle}
                                                checked={qualityCoverage}
                                                onChange={() => setQualityCoverage(!qualityCoverage)}
                                                color="primary"
                                                style={{color: '#FAFAFA'}}
                                            />
                                        }
                                        key={5}
                                        label={'QUALITY/ COVERAGE'}
                                        style={{marginLeft: '0.2%'}}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={styles.divToggles}>
                            {filterUniqueRoles(data2?.preProcessedData)
                                ?.sort((a: any, b: any) => customOrder[a.role] - customOrder[b.role])
                                ?.map((data: any) => (
                                    <div
                                        onClick={() => handleChangeSeeEmbryo(data)}
                                        className={data.seeEmbryo ? styles.embryoChecked : styles.embryoNotChecked}>
                                        <div className={data.seeEmbryo ? styles.labelChecked : styles.labelNotChecked}>
                                            {data.sampleName}
                                        </div>
                                        {data.seeEmbryo ? (
                                            <CheckCircleIcon className={styles.iconChecked}/>
                                        ) : (
                                            <CancelIcon className={styles.iconNotChecked}/>
                                        )}
                                    </div>
                                ))}
                        </div>
                        <div className={styles.overflows}>
                            <div className={styles.container2}>
                                {filterUniqueRoles(data2?.preProcessedData)
                                    ?.sort((a, b) => customOrder[a.role] - customOrder[b.role])
                                    ?.map((data: any, i: any) => (
                                        <>
                                            {i == 0 && (
                                                <div className={styles.container0}>
                                                    {props.currentStep == 2 && (
                                                        <Button
                                                            onClick={openModalAddMutation}>{t('addMutation')}</Button>
                                                    )}
                                                    <div className={styles.chromPos}>
                                                        <div className={styles.chromContainer}>{t('chrom')}</div>
                                                        <div className={styles.borderContainer}/>
                                                        <div className={styles.posContainer}>{t('position')}</div>
                                                    </div>
                                                </div>
                                            )}
                                            {renderHeader(data)}
                                        </>
                                    ))}
                            </div>
                            <div className={styles.scrollContainer}>
                                {data2?.resultTableData?.map((data: any) => (
                                    <>{getDataComponentFiltered(data)}</>
                                ))}
                            </div>
                        </div>
                        {props.currentStep == 1 && (
                            <div className={styles.footerContainer}>
                                {project?.infoAnalizedBy !== '' ? (
                                    <div className={styles.analizeByDiv}>
                                        <Typography>{t('analizeBy') + project?.infoAnalizedBy}</Typography>
                                    </div>
                                ) : (
                                    <div className={styles.analizeByDiv}>
                                        <FormControlLabel
                                            className={styles.toggle}
                                            control={
                                                <Switch
                                                    checked={project.infoAnalizedBy !== ''}
                                                    onChange={handleInfoAnalizedByChangeProject}
                                                />
                                            }
                                            label={t('isAnalize')}
                                            style={{marginLeft: '0.2%'}}
                                        />
                                    </div>
                                )}

                                {project?.infoValidateBy !== '' ? (
                                    <div className={styles.validateByDiv}>
                                        <Typography>{t('validateBy') + project?.infoValidateBy}</Typography>
                                    </div>
                                ) : (
                                    <div className={styles.validateByDiv}>
                                        <FormControlLabel
                                            className={styles.toggle}
                                            control={
                                                <Switch
                                                    checked={project.infoValidateBy !== ''}
                                                    onChange={handleChangeInfoValidate}
                                                />
                                            }
                                            label={t('isValidate')}
                                            style={{marginLeft: '0.2%'}}
                                        />
                                    </div>
                                )}

                                <div className={styles.buttonDiv}>
                                    <Button onClick={openSaveMutationModal} className={classes.button}>
                                        {t('ok')}
                                    </Button>
                                </div>
                            </div>
                        )}
                        {props.currentStep == 2 && (
                            <div className={styles.footerContainer}>
                                {project?.embryoAnalizedBy !== '' ? (
                                    <div className={styles.analizeByDiv}>
                                        <Typography>{t('analizeBy') + project?.embryoAnalizedBy}</Typography>
                                    </div>
                                ) : (
                                    <div className={styles.analizeByDiv}>
                                        <FormControlLabel
                                            className={styles.toggle}
                                            control={
                                                <Switch
                                                    checked={project.embryoAnalizedBy !== ''}
                                                    onChange={handleEmbryoAnalizedByChangeProject}
                                                />
                                            }
                                            label={t('isAnalize')}
                                            style={{marginLeft: '0.2%'}}
                                            disabled={props.currentStep !== 2}
                                        />
                                    </div>
                                )}

                                {project?.embryoValidateBy !== '' ? (
                                    <div className={styles.validateByDiv}>
                                        <Typography>{t('validateBy') + project?.embryoValidateBy}</Typography>
                                    </div>
                                ) : (
                                    <div className={styles.validateByDiv}>
                                        <FormControlLabel
                                            className={styles.toggle}
                                            control={
                                                <Switch
                                                    checked={project.embryoValidateBy !== ''}
                                                    onChange={handleChangeEmbryoValidate}
                                                />
                                            }
                                            label={t('isValidate')}
                                            style={{marginLeft: '0.2%'}}
                                            disabled={props.currentStep !== 2}
                                        />
                                    </div>
                                )}

                                <div className={styles.buttonDiv}>
                                    <Button onClick={openSaveMutationModal} className={classes.button}>
                                        {t('ok')}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
            <Modal open={alleleModal} onClose={() => setAlleleModal(false)}>
                <div className={styles3.containerAllele}>
                    <div className={styles3.alleleTitle}>{t('chooseAllele')}</div>
                    <div className={styles3.alleleDivs}>
                        <FormControl className={styles3.formControl}>
                            <InputLabel>{t('selectColor')}</InputLabel>
                            <Select value={selectedOption} onChange={handleChange}>
                                {options.map((option) => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                        style={{backgroundColor: option.value}}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <CloseIcon className={styles3.alleleCloseIcon} onClick={() => setAlleleModal(false)}/>
                </div>
            </Modal>
            <Modal open={modalAddMutation}>
                <Box className={styles2.modal}>
                    <form onSubmit={addMutation}>
                        <Box mt={2} textAlign={'justify'}>
                            {t('addMutationModalMessage')}
                        </Box>
                        <Box mt={2} textAlign={'justify'}>
                            <TextField
                                fullWidth
                                variant={'outlined'}
                                id={'mutation'}
                                type={'string'}
                                label={t('mutationLabel')}
                                onChange={(e) => handleMutation('name', e.target.value)}
                            />
                        </Box>
                        <FormActions actions={actionsDelete} message={t('save')}/>
                    </form>
                </Box>
            </Modal>

            <Modal
                open={props.currentStep == 2 && saveMutationModal}
                onClose={() => closeSaveMutationModal()}>
                <div className={styles.containerAllele}>
                    <div className={styles.alleleTitle}>{t('mutationSave')}</div>
                    <div className={styles.alleleDivs}>
                        <div className={styles.allele} onClick={() => saveMutationsAndClose()}>
                            {t('yes')}
                        </div>
                        <div className={styles.allele} onClick={() => closeSaveMutationModal()}>
                            {t('no')}
                        </div>
                    </div>
                    <CloseIcon className={styles.alleleCloseIcon} onClick={() => closeSaveMutationModal()}/>
                </div>
            </Modal>

            <Modal open={props.currentStep == 2 && changesModal} onClose={() => closeChangesModal()}>
                <div className={styles.containerAllele}>
                    <div className={styles.alleleTitle}>{t('mutationSave')}</div>
                    <div className={styles.alleleDivs}>
                        <div className={styles.allele} onClick={() => saveChanges()}>
                            {t('yes')}
                        </div>
                        <div className={styles.allele} onClick={() => closeChangesModal()}>
                            {t('no')}
                        </div>
                    </div>
                    <CloseIcon className={styles.alleleCloseIcon} onClick={() => closeChangesModal()}/>
                </div>
            </Modal>
        </>
    )
}
