import { Container } from '../../common/container/Container'

export type EmbryoContainerConfig = {
  moduleFullUrl: string
}

export type EmbryoProps = {
  parentContainer: Container
  config: EmbryoContainerConfig
}

export const EMBRYO_API_KEY = Symbol('EMBRYO_API_KEY')
export const EMBRYO_SERVICE_KEY = Symbol('EMBRYO_SERVICE_KEY')
export const EMBRYO_MODULE = Symbol('EMBRYO_MODULE')
