import { RouteProps } from '../../routes/AppRouter'
import { Grid, Button, TextField } from '@material-ui/core'
import { useForm } from '../../common/utils/form-generation/useForm'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import { URL_PATIENT_PGD_SHOW, URL_PGD_TEST } from '../../routes/routes-constants'
import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Query, QueryParam } from '../../common/api/Query'
import { Project, ProjectDTO, emptyProjectDTO } from '../../modules/projects/models/Project'
import { getProjectContainer } from '../../container/project-module'
import { ProjectService } from '../../modules/projects/services/ProjectService'
import { PROJECT_SERVICE_KEY } from '../../modules/projects'
import { userGenders } from '../../modules/users/enums/GenderType'
import { getFileContainer } from 'container/file-module'
import { FileService } from 'modules/files/services/FileService'
import { FILE_SERVICE_KEY } from 'modules/files'
import { Bedfile, Panel } from 'modules/files/models/File'
import { useStyles } from '../../components/entity-page/EntityPage.styles'

const projectService = getProjectContainer().get<ProjectService>(PROJECT_SERVICE_KEY)
const fileService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)

export type FormProps = RouteProps & {
  project: Project | undefined
  patientID: string
}

export const Detail = (props: FormProps) => {
  const { t } = useTranslation()
  const [panels, setPanels] = useState<Panel[]>([])
  const [bedfiles, setBedfiles] = useState<Bedfile[]>([])
  const [geneSelected, setGeneSelected] = useState<string>('')
  const [panelSelected, setPanelSelected] = useState<Panel>()
  const classes = useStyles({})

  useEffect(() => {
    if (!geneSelected) return
    fileService
      .getBedfiles(
        new Query({
          query: [new QueryParam('gene', geneSelected)],
        })
      )
      .subscribe((res) => {
        setBedfiles(res.items)
      })
  }, [geneSelected])

  useEffect(() => {
    fileService.getPanels(new Query({})).subscribe((res) => {
      setPanels(res.items)
    })
  }, [])

  useEffect(() => {
    if (!props.project) {
      return
    }

    fileService.getBedfileByID(props.project.toDTO().bedfileID).subscribe((res) => {
      res && setBedfiles([res])
    })
  }, [props.project])

  useEffect(() => {
    if (!props.project) {
      return
    }
    setData(props.project.toDTO())
  }, [props.project])

  const { handleChange, handleSubmit, data, setData, errors } = useForm<ProjectDTO>({
    validations: {
      name: {
        required: {
          value: true,
          message: t('projectNameNotValidError'),
        },
      },
    },

    onSubmit: () => {
      function storeCb(value: Project | undefined) {
        if (value) {
          goToProyects().then()
        }
      }

      if (!props.project) {
        projectService
          .add({
            ...data,
            id: uuidv4(),
            pathology: panelSelected?.pathology || '',
          })
          .subscribe((value) => storeCb(value))
        return
      }

      projectService.update({ ...data }).subscribe((value) => storeCb(value))
    },

    initialValues: props.project ? props.project.toDTO() : emptyProjectDTO(props.patientID),
  })

  const goToProyects = () => props.patientID ? navigate(URL_PATIENT_PGD_SHOW.replace(':id', props.patientID)) : navigate(URL_PGD_TEST)

  const getBedfileName = (bedID: string) => {
    let filtered = bedfiles.filter((b) => b.id == bedID)

    if (filtered.length > 0) {
      return filtered[0].name
    }
    return ''
  }

  const getPanelName = (panelID: string) => {
    let filtered = panels.filter((b) => b.id == panelID)

    if (filtered.length > 0) {
      return filtered[0].gene
    }
    return ''
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} style={{ marginBottom: '1%' }}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={'outlined'}
            error={errors['name'] !== undefined}
            id={'name'}
            type={'string'}
            onChange={(event) => handleChange('name', event.target.value)}
            value={(data && data.name) || ''}
            label={t('projectName')}
            helperText={errors['name']}
            inputProps={{
              readOnly: true,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={'outlined'}
            error={errors['nameHer'] !== undefined}
            id={'nameHer'}
            type={'string'}
            onChange={(event) => handleChange('nameHer', event.target.value)}
            value={(data && data.nameHer) || ''}
            label={t('nameHer')}
            helperText={errors['nameHer']}
            inputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={'outlined'}
            error={errors['dobHer'] !== undefined}
            id={'dobHer'}
            type={'string'}
            value={new Date(data.dobHer).toLocaleDateString()}
            label={t('dobHer')}
            inputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={'outlined'}
            error={errors['nifHer'] !== undefined}
            id={'nifHer'}
            type={'string'}
            onChange={(event) => handleChange('nifHer', event.target.value)}
            value={(data && data.nifHer) || ''}
            label={t('nifHer')}
            helperText={errors['nifHer']}
            inputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={'outlined'}
            error={errors['nameHim'] !== undefined}
            id={'nameHim'}
            type={'string'}
            onChange={(event) => handleChange('nameHim', event.target.value)}
            value={(data && data.nameHim) || ''}
            label={t('nameHim')}
            helperText={errors['nameHim']}
            inputProps={{
              readOnly: true,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={'outlined'}
            error={errors['dobHim'] !== undefined}
            id={'dobHim'}
            type={'string'}
            value={new Date(data.dobHim).toLocaleDateString()}
            label={t('dobHim')}
            inputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={'outlined'}
            error={errors['nifHim'] !== undefined}
            id={'nifHim'}
            type={'string'}
            onChange={(event) => handleChange('nifHim', event.target.value)}
            value={(data && data.nifHim) || ''}
            label={t('nifHim')}
            helperText={errors['nifHim']}
            inputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={'outlined'}
            error={errors['panelID'] !== undefined}
            id={'panelID'}
            type={'string'}
            //@ts-ignore
            value={getPanelName(data.panelID)}
            label={t('panel')}
            helperText={errors['panelID']}
            inputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={'outlined'}
            error={errors['bedfileID'] !== undefined}
            id={'bedfileID'}
            type={'string'}
            //@ts-ignore
            value={getBedfileName(data.bedfileID)}
            label={t('bedfile')}
            helperText={errors['bedfileID']}
            inputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={'outlined'}
            error={errors['pathology'] !== undefined}
            id={'pathology'}
            type={'string'}
            value={(panelSelected && panelSelected.pathology) || (data && data.pathology) || ''}
            label={t('pathology')}
            helperText={errors['pathology']}
            inputProps={{
              readOnly: true,
              disabled: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={'outlined'}
            error={errors['receptionDate'] !== undefined}
            id={'receptionDate'}
            type={'string'}
            value={new Date(data.receptionDate).toLocaleDateString()}
            label={t('receptionDate')}
            inputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={'outlined'}
            error={errors['reportDate'] !== undefined}
            id={'reportDate'}
            type={'string'}
            value={new Date(data.reportDate).toLocaleDateString()}
            label={t('reportDate')}
            inputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={2} style={{marginBottom: "2.5%", marginTop: "2.5%"}}>
        <Button
          fullWidth
          className={classes.button}
          onClick={() => goToProyects()}
          variant={'contained'}>
          {t('back')}
        </Button>
      </Grid>
    </form>
  )
}
