import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import {COLOR_LOGIN, COLOR_PRIMARY} from "../../routes/color-constants";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.palette.grey['100'],
    minWidth: '100%',
    minHeight: '100%',
  },
  registerButton: {
    background: 'rgb(255, 255, 255)',
    color: COLOR_PRIMARY,
    display: 'flex',
    marginTop: 10,
    marginLeft: 70,
    marginRight: 70,
  },
  loginButton: {
    background: COLOR_LOGIN,
    color: theme.palette.common.white,
    display: 'flex',
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    minWidth: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  card: {
    display: 'flex',
    alignItems: 'center',
  },
  cardContent: {
    padding: 0,
    minWidth: '600px',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  registerCard: {
    background: COLOR_LOGIN,//'rgb(2, 152, 235)',
    padding: 25,
  },
  innerGrid: {
    padding: 25,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  containerForm: {
    color: theme.palette.grey['600'],
  },
  logo: {
    height: '150px',
    marginBottom: '50px',
  },
  link: {
    cursor: 'pointer',
    align: 'right',
    color: 'inherit',
  },
  phraseBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  loginPhrase: {
    fontSize: "12.5px"
  },
  loginEmail: {
    fontSize: "12.5px",
    marginLeft: "5px",
    textDecorationLine: "underline",
    cursor: "pointer",
  }
}))
