import { RouteProps } from '../../routes/AppRouter'
import { FormControlLabel, Grid, Switch, TextField } from '@material-ui/core'
import { useForm } from '../../common/utils/form-generation/useForm'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import { URL_CLINICS } from '../../routes/routes-constants'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { FormAction, FormActions } from '../../common/utils/form-generation'
import { EntityMultipleSelect } from '../common'
import { Query } from '../../common/api/Query'
import { Clinic } from '../../modules/clinics/models/Clinic'
import { ClinicDTO, fromModel } from '../../modules/clinics/models/ClinicDTO'
import { getClinicContainer } from '../../container/clinic-modules'
import { ClinicService } from '../../modules/clinics/services/ClinicService'
import { CLINIC_SERVICE_KEY } from '../../modules/clinics'
import { getPatientContainer } from '../../container/patient-module'
import { PatientService } from '../../modules/patients/services/PatientService'
import { PATIENT_SERVICE_KEY } from '../../modules/patients'
import { PatientReceptiveness } from '../../modules/patients/models/PatientReceptiveness'
import { getAuthContainer } from '../../container/auth-modules'
import { IAuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import { Permission } from '../../common/enums/Permissions'
import { commonStyles } from '../../common/styles/Styles'
import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { dataToBase64 } from '../../common/files/file'

const clinicService = getClinicContainer().get<ClinicService>(CLINIC_SERVICE_KEY)
const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)
const authService = getAuthContainer().get<IAuthService>(AUTH_SERVICE_KEY)
const fileService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)

export type FormProps = RouteProps & {
  clinic: Clinic | undefined
}

export const Form = (props: FormProps) => {
  const { t } = useTranslation()
  const [patients, setPatients] = useState<PatientReceptiveness[]>([])
  const [header, setHeader] = useState<File>()
  const [signature, setSignature] = useState<File>()
  const [dataHeader, setDataHeader] = useState<string>()
  const [dataSignature, setDataSignature] = useState<string>()

  const styles = commonStyles()

  const fetchPatients = () => {
    patientService
      .getFilteredList(
        new Query({
          sort: [{ field: 'firstName' }],
        })
      )
      .subscribe((res) => setPatients(res.items))
  }

  function blobToString(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsText(blob)
      reader.onload = () => {
        resolve(reader.result as string)
      }
      reader.onerror = reject
    })
  }

  useEffect(() => {
    if (props.clinic) {
      fileService.getSignatureByID(props.clinic.signature).subscribe((res) => {
        if (res) {
          const data = new Blob([res?.data], { type: res?.mimeType })
          blobToString(data).then((dataSig) => {
            setDataSignature(dataSig)
            setSignature(new File([dataSig], res?.name, { type: res?.mimeType }))
          })
        }
      })

      fileService.getHeaderByID(props.clinic.header).subscribe((res) => {
        if (res) {
          const data = new Blob([res?.data], { type: res?.mimeType })
          blobToString(data).then((dataHead) => {
            setDataHeader(dataHead)
            setHeader(new File([dataHead], res?.name, { type: res?.mimeType }))
          })
        }
      })
    }
  }, [props.clinic])

  useEffect(() => {
    props.clinic && setData(fromModel(props.clinic))
  }, [props.clinic])

  useEffect(() => {
    fetchPatients()
  }, [])

  const { handleChange, handleSubmit, data, setData, errors } = useForm<ClinicDTO>({
    validations: {
      name: {
        required: {
          value: true,
          message: t('clinicNameNotValidError'),
        },
      },
      address: {
        required: {
          value: true,
          message: t('addressNotValidError'),
        },
      },
      cp: {
        required: {
          value: true,
          message: t('cpNotValidError'),
        },
      },
      locality: {
        required: {
          value: true,
          message: t('localityNotValidError'),
        },
      },
      cif: {
        custom: {
          isValid: () => true,
          message: t('cifNotValidError'),
        },
      },
      email: {
        pattern: {
          value: '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$',
          message: t('emailNotValidError'),
        },
      },
      phone: {
        pattern: {
          value: '(^$|^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$)',
          message: t('phoneNotValidError'),
        },
      },
      header: {
        custom: {
          isValid: () => !!header?.name.endsWith('png'),
          message: t('notPNG'),
        },
      },
      signature: {
        custom: {
          isValid: () => !!signature?.name.endsWith('png'),
          message: t('notPNG'),
        },
      },
    },

    onSubmit: () => {
      const headerID = uuidv4()
      const signatureID = uuidv4()
      if (data.id) {
        clinicService
          .update({ ...data, header: headerID, signature: signatureID })
          .subscribe(async () => {
            if (header) {
              const fileData = await dataToBase64(header)
              fileService
                .addHeader({
                  id: headerID,
                  name: header.name,
                  data: dataHeader ? dataHeader : fileData,
                  size: header.size,
                  mimeType: header.type,
                  extension: header.type.split('/')[1],
                  ownerID: authService.get().id,
                })
                .subscribe((res) => {})
            }
            if (signature) {
              const fileData = await dataToBase64(signature)
              fileService
                .addSignature({
                  id: signatureID,
                  name: signature.name,
                  data: dataSignature ? dataSignature : fileData,
                  size: signature.size,
                  mimeType: signature.type,
                  extension: signature.type.split('/')[1],
                  ownerID: authService.get().id,
                })
                .subscribe((res) => {})
            }
            goToClinics().then()
          })
        return
      }
      clinicService
        .add({ ...data, id: uuidv4(), header: headerID, signature: signatureID })
        .subscribe(async () => {
          authService.update().subscribe()
          if (header) {
            const fileData = await dataToBase64(header)
            fileService.addHeader({
              id: headerID,
              name: header.name,
              data: fileData,
              size: header.size,
              mimeType: header.type,
              extension: header.type.split('/')[1],
              ownerID: authService.get().id,
            })
          }
          if (signature) {
            const fileData = await dataToBase64(signature)
            fileService.addSignature({
              id: signatureID,
              name: signature.name,
              data: fileData,
              size: signature.size,
              mimeType: signature.type,
              extension: signature.type.split('/')[1],
              ownerID: authService.get().id,
            })
          }
          goToClinics().then()
        })
    },
    initialValues: props.clinic && fromModel(props.clinic),
  })

  const goToClinics = () => navigate(URL_CLINICS)

  const actions: FormAction[] = [
    {
      label: t('back'),
      handleAction: () => goToClinics(),
    },
  ]

  const handleFileHeaderInput = async (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const files = (event.target as HTMLInputElement).files
    files && files.length && setHeader(files[0])

    if (files && files.length) {
      const fileData = await dataToBase64(files[0])
      setDataHeader(fileData)
    }
  }

  const handleFileSignatureInput = async (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const files = (event.target as HTMLInputElement).files
    files && files.length && setSignature(files[0])
    if (files && files.length) {
      const fileData = await dataToBase64(files[0])
      setDataSignature(fileData)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={'outlined'}
            error={errors['name'] !== undefined}
            id={'name'}
            type={'string'}
            onChange={(event) => handleChange('name', event.target.value)}
            value={(data && data.name) || ''}
            label={t('clinic') + ' *'}
            helperText={errors['name']}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={'outlined'}
            id={'idClinic'}
            type={'string'}
            onChange={(event) => handleChange('idClinic', event.target.value)}
            value={(data && data.idClinic) || ''}
            label={t('idClinic')} 
            helperText={errors['idClinic']}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={'outlined'}
            error={errors['address'] !== undefined}
            id={'address'}
            type={'string'}
            onChange={(event) => handleChange('address', event.target.value)}
            value={(data && data.address) || ''}
            label={t('address') + ' *'}
            helperText={errors['address']}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={'outlined'}
            error={errors['cp'] !== undefined}
            id={'cp'}
            type={'string'}
            onChange={(event) => handleChange('cp', event.target.value)}
            value={(data && data.cp) || ''}
            label={t('cp') + ' *'}
            helperText={errors['cp']}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={'outlined'}
            error={errors['locality'] !== undefined}
            id={'locality'}
            type={'string'}
            onChange={(event) => handleChange('locality', event.target.value)}
            value={(data && data.locality) || ''}
            label={t('locality') + ' *'}
            helperText={errors['locality']}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={'outlined'}
            error={errors['cif'] !== undefined}
            id={'cif'}
            type={'string'}
            onChange={(event) => handleChange('cif', event.target.value)}
            value={(data && data.cif) || ''}
            label={t('cif')}
            helperText={errors['cif']}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={errors['odooNumber'] !== undefined}
            fullWidth
            variant={'outlined'}
            id="odooNumber"
            type={'number'}
            label={t('odooNumber')}
            onChange={(event) => handleChange('odooNumber', +event.target.value)}
            value={data && data.odooNumber}
            helperText={errors['odooNumber']}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        {data.activeReceptiveness && (
          <>
            {authService.get().permissions.find((p) => p === Permission.editClinics) && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant={'outlined'}
                  id="remainingCredits"
                  type={'number'}
                  label={t('remainingCredits')}
                  onChange={(event) => handleChange('remainingCredits', +event.target.value)}
                  value={data && data.remainingCredits}
                  helperText={errors['remainingCredits']}
                  InputProps={{ inputProps: { min: 0 } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            )}
          </>
        )}
        <Grid item xs={12}>
          <TextField
            error={errors['email'] !== undefined}
            fullWidth
            variant={'outlined'}
            id="email"
            type={'email'}
            label={t('email') + ' *'}
            onChange={(event) => handleChange('email', event.target.value)}
            value={data && data.email}
            helperText={errors['email']}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={errors['phone'] !== undefined}
            fullWidth
            variant={'outlined'}
            id="phone"
            type={'phone'}
            label={t('phone') + ' *'}
            onChange={(event) => handleChange('phone', event.target.value)}
            value={data && data.phone}
            helperText={errors['phone']}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            error={errors['header'] !== undefined}
            helperText={errors['header']}
            name={'header'}
            variant={'outlined'}
            onChange={(event) => handleFileHeaderInput(event)}
            type={'file'}
            label={t('header') + ' *'}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            error={errors['signature'] !== undefined}
            helperText={errors['signature']}
            name={'signature'}
            variant={'outlined'}
            onChange={(event) => handleFileSignatureInput(event)}
            type={'file'}
            label={t('signature')  + ' *'}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={'outlined'}
            error={errors['signedBy'] !== undefined}
            id={'signedBy'}
            type={'string'}
            onChange={(event) => handleChange('signedBy', event.target.value)}
            value={(data && data.signedBy) || ''}
            label={t('signedBy')}
            helperText={errors['signedBy']}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={'outlined'}
            error={errors['roleSignature'] !== undefined}
            id={'roleSignature'}
            type={'string'}
            onChange={(event) => handleChange('roleSignature', event.target.value)}
            value={(data && data.roleSignature) || ''}
            label={t('roleSignature')}
            helperText={errors['roleSignature']}
          />
        </Grid>
        {/*<Grid item xs={12}>
          <EntityMultipleSelect
            name={(option) => option.firstName + ' ' + option.lastName}
            values={data && data.patients}
            options={patients}
            onChange={(values) =>
              handleChange(
                'patients',
                values.map((v) => v.id)
              )
            }
            label={t('Patients')}
            pk={'id'}
          />
        </Grid>*/}
        <div style={{flexDirection: "row", width: "100%", marginTop: "2%", marginBottom: "2%"}}>
        <div style={{width: "27%", display: "flex", justifyContent: "space-between"}}>
          <FormControlLabel
            className={styles.toggle}
            control={<Switch defaultChecked />}
            label={t('receptiveness')}
            checked={data.activeReceptiveness == true}
            style={{ marginLeft: '1%' }}
            onChange={() => handleChange('activeReceptiveness', !data.activeReceptiveness)}
          />
         
        </div>
        <div style={{width: "27%", display: "flex", justifyContent: "space-between", flexDirection: "row", marginTop: "1%"}}>
          <FormControlLabel
            className={styles.toggleLarge}
            control={<Switch defaultChecked />}
            label={t('pgdseq')}
            style={{ marginLeft: '1%' }}
            checked={data.activePgd == true}
            onChange={() => handleChange('activePgd', !data.activePgd)}
          />
        </div>
        </div>
      </Grid>
      <FormActions actions={actions} />
    </form>
  )
}
