import { RouteProps } from '../../routes/AppRouter'
import { Title } from '../../components/common/Title'
import { TITLE_PROYECTS } from '../../routes/title-constants'
import { useTranslation } from 'react-i18next'
import { ProjectsTable } from '../../features/projects'

export const Table = (props: RouteProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Title title={t(TITLE_PROYECTS)} color={props.color} />
      <ProjectsTable />
    </>
  )
}
