import {Container, ContainerItem} from "../common/container/Container";
import {
    MUTATIONS_POSITION_API_KEY, MUTATIONS_POSITION_MODULE,
    MUTATIONS_POSITION_SERVICE_KEY,
    MutationsPositionProps
} from "../modules/mutationsPosition/container";
import {MutationsPositionService} from "../modules/mutationsPosition/services/MutationsPositionService";
import {MutationsPositionsApi} from "../modules/mutationsPosition/api/MutationsPositionsApi";
import {getAppContainer} from "./app";
import {moduleURLs} from "../config/api";


let container: Container

function init(p: MutationsPositionProps) {
    const items = new Map<symbol, ContainerItem>()

    items.set(MUTATIONS_POSITION_API_KEY, new ContainerItem({ instance: new MutationsPositionsApi() }))
    items.set(
        MUTATIONS_POSITION_SERVICE_KEY,
        new ContainerItem({
            instance: new MutationsPositionService({ apiKey: MUTATIONS_POSITION_API_KEY }),
        })
    )

    container = new Container(items, p.parentContainer, p.config)
}

export function getMutationsPositionContainer(): Container {
    if (!container) {
        init({
            parentContainer: getAppContainer(),
            config: {
                moduleFullUrlMutationsPosition: moduleURLs[MUTATIONS_POSITION_MODULE],
            },
        })
    }

    return container
}