import { Container } from '../../common/container/Container'


export type MutationsPositionContainerConfig = {
    moduleFullUrlMutationsPosition: string
}

export type MutationsPositionProps = {
    parentContainer: Container
    config: MutationsPositionContainerConfig
}

export const MUTATIONS_POSITION_API_KEY = Symbol('MUTATIONS_POSITION_API_KEY')
export const MUTATIONS_POSITION_SERVICE_KEY = Symbol('MUTATIONS_POSITION_SERVICE_KEY')
export const MUTATIONS_POSITION_MODULE = Symbol('MUTATIONS_POSITION_MODULE')