import { Box, Button, Container, Grid, Modal, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { COLOR_CLINICS, COLOR_PRIMARY, COLOR_SECONDARY } from '../../../routes/color-constants'
import { useStyles } from '../Detail.styles'
import { RouteProps } from '../../../routes/AppRouter'
import React, { useEffect, useState } from 'react'
import { Actions, Field, Pager, Sort } from '../../../components/table/types'
import { AppTable } from '../../../components/table'
import { Query, SortParam } from '../../../common/api/Query'
import { emptyPatientPGDDTO, PatientPGDDTO } from '../../../modules/patients/models/PatientPGD'
import { PatientService } from '../../../modules/patients/services/PatientService'
import { PATIENT_SERVICE_KEY } from '../../../modules/patients'
import { getPatientContainer } from '../../../container/patient-module'
import { TITLE_PROYECTS } from '../../../routes/title-constants'
import { Title } from '../../../components/common/Title'
import { Permission } from '../../../common/enums/Permissions'
import { getAuthContainer } from '../../../container/auth-modules'
import { AuthService } from '../../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../../modules/auth'
import { URL_PROYECT, URL_SHOW_PROYECT } from 'routes/routes-constants'
import { navigate } from '@reach/router'
import { Project } from 'modules/projects/models/Project'
import { getProjectContainer } from 'container/project-module'
import { ProjectService } from 'modules/projects/services/ProjectService'
import { PROJECT_SERVICE_KEY } from 'modules/projects'
import { userGenders } from 'modules/users/enums/GenderType'
import seeIcon from '../../../assets/table-icons/see-icon.svg'
import editIcon from '../../../assets/table-icons/edit-icon.svg'
import deleteIcon from '../../../assets/table-icons/delete-icon.svg'
import { FormAction, FormActions } from 'common/utils/form-generation'
import { commonStyles } from 'common/styles/Styles'
import downloadIcon from '../../../assets/table-icons/download-icon.svg'

type DetailProps = {
  id?: string
}

const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)
const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)
const projectService = getProjectContainer().get<ProjectService>(PROJECT_SERVICE_KEY)

export const Detail = (props: DetailProps) => {
  return (
    <Container>
      <PatientDetail {...props} />
      <PatientProject {...props} />
    </Container>
  )
}

export const PatientDetail = (props: RouteProps) => {
  const { t } = useTranslation()
  const classes = useStyles({ color: COLOR_CLINICS })
  const [patient, setPatient] = useState<PatientPGDDTO>(emptyPatientPGDDTO())

  useEffect(() => {
    if (!props.id) {
      return
    }
    patientService.getByIDPGD(props.id).subscribe((res) => {
      if (res) {
        setPatient(res.toDTO())
      }
    })
  }, [])

  return (
    <Container className={classes.container}>
      <Grid container>
        <Grid item xs={3} alignItems={'stretch'}>
          <Box className={classes.box}>
            <Typography align={'left'} variant={'h5'} className={classes.fullName}>
              {patient.firstName + ' ' + patient.lastName}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={9}>
          <Box className={classes.box} borderLeft={1}>
            <Box display="flex" mb={0} p={0}>
              <div className={classes.text}>
                <b>{t('idPatient')}</b>: {patient.idPatient}
              </div>
            </Box>
            <Box display="flex" mb={0} p={0}>
              <div className={classes.text}>
                <b>{t('firstName')}</b>: {patient.firstName}
              </div>
            </Box>
            <Box display="flex" mb={0} p={0}>
              <div className={classes.text}>
                <b>{t('lastName')}</b>: {patient.lastName}
              </div>
            </Box>
            <Box display="flex" mb={0} p={0}>
              <div className={classes.text}>
                {/**@ts-ignore */}
                <b>{t('dob')}</b>: {new Date(patient.dob).toLocaleDateString()}
              </div>
            </Box>
            <Box display="flex" mb={0} p={0}>
              <div className={classes.text}>
                <b>{t('creationDate')}</b>: {new Date(patient.creationDate).toLocaleDateString()}
              </div>
            </Box>
            <Box display="flex" mb={0} p={0}>
              <div className={classes.text}>
                <b>{t('gender')}</b>: {userGenders()[patient.gender]}
              </div>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export const PatientProject = (props: RouteProps) => {
  const { t } = useTranslation()
  const classes = useStyles({ color: COLOR_CLINICS })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pager, setPager] = useState<Pager>()
  const [page, setPage] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [items, setItems] = useState<Project[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const [currentProject, setCurrentProject] = useState<Project>()
  const [sort, setSort] = useState<SortParam<Project>>({
    field: 'creationDate',
    desc: true,
  })

  useEffect(() => {
    if (!isLoading) {
      return
    }
    projectService.getByUserID(props.id || "").subscribe((res) => {
      setItems(res.items)
      setIsLoading(false)
      setCount(res.count)
    })
  }, [isLoading])

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: itemsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, itemsPerPage])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setItemsPerPage(10)
      return
    }
    setItemsPerPage(Number.parseInt(event.target.value))
  }

  const fields: Field<Project>[] = [
    {
      sortable: true,
      label: t('name'),
      name: 'name',
    },
    {
      sortable: true,
      label: t('nameHer'),
      name: 'nameHer',
    },
    {
      sortable: true,
      label: t('nameHim'),
      name: 'nameHim',
    },
    {
      label: t('creationDate'),
      name: 'creationDate',
      renderFunc: (f, i) => new Date(i.creationDate).toLocaleDateString(),
    },
  ]

  const deleteConfirmed = () => {
    projectService.delete(currentProject?.id || '').subscribe(()=> {
      setIsLoading(true)
    })
  }

  const handleDelete = (project: Project) => {
    setCurrentProject(project)
    setOpen(true)
  }  

  const handleDownloadReport = (project: Project) => {
    //TODO
  }

  const actions: Actions<Project> = {
    actionsColumn: t('Actions'),
    items: [
      {
        icon: seeIcon,
        label: t('Show'),
        handler: (item) => navigate(URL_SHOW_PROYECT.replace(':patientID', props.id || '').replace(':id', item.id || "")),
      },
      {
        handler: (item) =>
          navigate(URL_PROYECT.replace(':patientID', props.id || '').replace(':id', item.id)),
        icon: editIcon,
        label: t('Edit'),
      },
      {
        handler: (pr) => handleDelete(pr),
        icon: deleteIcon,
        label: t('Delete'),
      },
      {
        handler: (pr) => handleDownloadReport(pr),
        icon: downloadIcon,
        label: t('download'),
        hidden: (item) => item.status,
      },
    ],
  }

  const sortable: Sort<Project> = {
    name: sort.field,
    direction: sort.desc ? 'desc' : 'asc',
    handleSort: (field) => {
      setSort({ field: field, desc: sort.field === field ? !sort.desc : true })
      setIsLoading(true)
    },
  }
  const styles = commonStyles()

  const createProyect = () =>
    navigate(URL_PROYECT.replace(':patientID', props.id || '').replace(':id', 'create'))


  const closeModal = () => {
    setOpen(false)
    setCurrentProject(undefined)
  }

  const actionsDelete: FormAction[] = [
    {
      label: t('close'),
      handleAction: closeModal,
    },
  ]

  return (
    <>
      <Title color={COLOR_SECONDARY} title={t(TITLE_PROYECTS)} />
      {/*<Box justifyContent={'space-between'} mb={2}>
          <Button
            className={classes.button}
            variant={'contained'}
            onClick={createProyect}
            style={{ float: 'right' }}>
            {t('create')}
          </Button>
      </Box>*/}
      <AppTable
        styleHeader={{ color: COLOR_PRIMARY }}
        actions={actions}
        fields={fields}
        items={items}
        rowKeyField={'patientID'}
        pager={pager}
        sort={sortable}
      />
      <Modal open={open}>
        <Box className={styles.modal}>
          <form onSubmit={deleteConfirmed}>
            <Box mt={2} textAlign={'justify'}>
              {t('projectConfirmationMessage')}
            </Box>
            <FormActions actions={actionsDelete} message={t('confirm')} />
          </form>
        </Box>
      </Modal>
    </>
  )
}
