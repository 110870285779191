import { Fab, Tooltip } from '@material-ui/core'
import style from './GenericTooltip.module.css'
import { GenericToolTipListExpressions } from './GenericToolTipListExpressions'

export type GenericTooltipProps = {
  values: string[]
  icon: JSX.Element
  noUseFab?: boolean
}

export function GenericTooltip(props: GenericTooltipProps): JSX.Element {
  return (
    <Tooltip
      classes={{
        tooltip: style.genericTooltip,
      }}
      title={<GenericToolTipListExpressions values={props.values} />}
      enterTouchDelay={0}>
      {props.noUseFab ? (
        <div>{props.icon}</div>
      ) : (
        <Fab color="primary" aria-label="info">
          {props.icon}
        </Fab>
      )}
    </Tooltip>
  )
}
