import React, {CSSProperties} from 'react'
import {Table, TableContainer} from '@material-ui/core'
import {Head} from './Head'
import {Pagination} from './Pagination'
import {Body} from './Body'
import {Actions, Field, Pager, Search, Sort} from './types'
import {Searcher} from './Searcher'

const DEFAULT_ROW_KEY = 'id'

export type TableProps<T, Q> = {
  readonly fields: Field<T>[]
  readonly items: T[]
  readonly rowKeyField: keyof T
  readonly styleHeader?: CSSProperties
  readonly actions?: Actions<T>
  readonly search?: Search<Q>
  readonly sort?: Sort<T>
  readonly pager?: Pager
  readonly includesCheck?: boolean
  readonly handleCheck?: (e: T) => void
  readonly selectedID?: string
}

export function AppTable<T extends { [key: string]: any }, Q extends { [key: string]: any }>(
  props: TableProps<T, Q>
) {
  return (
    <>
      {props.search && <Searcher search={props.search} />}
      <TableContainer>
        <Table>
          <Head fields={props.fields} sort={props.sort} actions={props.actions} styleHeader={props.styleHeader} includesCheck={props.includesCheck} />
          <Body
            actions={props.actions}
            fields={props.fields}
            items={props.items}
            rowKeyField={props.rowKeyField || DEFAULT_ROW_KEY}
            includesCheck={props.includesCheck}
            handleCheck={props.handleCheck}
            selectedID={props.selectedID}
          />
        </Table>
      </TableContainer>

      {props.pager && <Pagination {...props.pager} />}
    </>
  )
}
