import { RouteProps } from '../../routes/AppRouter'
import { Title } from '../../components/common/Title'
import { TITLE_BEDFILES } from '../../routes/title-constants'
import { useTranslation } from 'react-i18next'
import { BedfilesForm } from '../../features/scripts/index'

export const Bedfiles = (props: RouteProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Title title={t(TITLE_BEDFILES)} color={props.color} />
      <BedfilesForm />
    </>
  )
}
