import i18n from 'i18n/i18n'
import {Option, OptionValue} from '../../../common/utils/form-generation/types'

export enum RoleType {
    BAAdmin = 1,
    BAUser,
    ClinicAdmin,
    ClinicUser,
}

export const optionRoleType = (): Option<OptionValue>[] => [
    {value: RoleType.BAAdmin, label: 'BAAdmin'},
    {value: RoleType.BAUser, label: 'BAUser'},
    {value: RoleType.ClinicAdmin, label: 'ClinicAdmin'},
    {value: RoleType.ClinicUser, label: 'ClinicUser'},
]

export const roleTypes = (): Record<RoleType, string> => ({
    [RoleType.BAAdmin]: i18n.t('BAAdmin'),
    [RoleType.BAUser]: i18n.t('BAUser'),
    [RoleType.ClinicAdmin]: i18n.t('ClinicAdmin'),
    [RoleType.ClinicUser]: i18n.t('ClinicUser'),
})

export const clinicUserRoles = (): { [RoleType.ClinicUser]: string } => ({
    [RoleType.ClinicUser]: i18n.t('ClinicUser'),
})

export enum RolePGDTest {
    FemaleFather = 0,
    Female,
    Male,
    Child,
    FemaleMother,
    MaleMother,
    MaleFather,
    Unknown,
    Embryo,
}

export const rolePgdTest = (): Record<RolePGDTest, string> => ({
    [RolePGDTest.FemaleFather]: 'Female-Father',
    [RolePGDTest.Female]: 'Female',
    [RolePGDTest.Male]: 'Male',
    [RolePGDTest.Child]: 'Child',
    [RolePGDTest.FemaleMother]: 'Female-Mother',
    [RolePGDTest.MaleMother]: 'Male-Mother',
    [RolePGDTest.MaleFather]: 'Male-Father',
    [RolePGDTest.Unknown]: "",
    [RolePGDTest.Embryo]: "embryo",
})

export const customOrder: any[] = [];
customOrder[RolePGDTest.FemaleMother] = 0;
customOrder[RolePGDTest.FemaleFather] = 1;
customOrder[RolePGDTest.MaleMother] = 2;
customOrder[RolePGDTest.MaleFather] = 3;
customOrder[RolePGDTest.Female] = 4;
customOrder[RolePGDTest.Male] = 5;
customOrder[RolePGDTest.Child] = 6;
customOrder[RolePGDTest.Unknown] = 7;
customOrder[RolePGDTest.Embryo] = 8;
