import {Container, IInit} from "../../../common/container/Container";
import {Word, WordDTO} from "../models/Word";
import {Observable} from "rxjs";
import {IWordApi} from "../api/WordApi";
import {IStatusService} from "../../../common/status/StatusService";
import {STATUS_SERVICE_KEY} from "../../../container/app";

type Props = {
    apiKey: symbol
}

export interface IWordService extends IInit {
    getByID(id: string): Observable<Word | undefined>

    update(e: WordDTO): Observable<WordDTO | undefined>
}

export class WordService implements IWordService {
    private readonly _apiKey: symbol
    private _container!: Container
    private _api!: IWordApi
    private _statusService!: IStatusService

    constructor(p: Props) {
        this._apiKey = p.apiKey
    }

    init(c: Container) {
        this._container = c
        this._api = this._container.get<IWordApi>(this._apiKey)
        this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    }

    getByID(id: string): Observable<Word | undefined> {
        return this._api.getByID(id)
    }

    update(e: WordDTO): Observable<WordDTO | undefined> {
        return this._api.update(e)
    }
}