import {emptyList, ItemList} from '../../../common/models/ItemList'
import {Observable, of} from 'rxjs'
import {Container, IInit} from '../../../common/container/Container'
import {HTTP_CLIENT_KEY, IHTTPClient} from '../../../common/api/HTTPClient'
import {IStatusService} from '../../../common/status/StatusService'
import {STATUS_SERVICE_KEY} from '../../../container/app'
import {Query} from '../../../common/api/Query'
import {Project, ProjectAnalysis, ProjectQuery} from '../models/Project'
import {ProjectContainerConfig} from '../container'
import {ProjectDTO, toModel} from '../models/Project'
import {prepareURL} from '../../../common/api/http-helpers'
import {catchError, map} from 'rxjs/operators'
import { File } from 'modules/files/models/File'

export interface IProjectApi extends IInit {
    getByID(id: string): Observable<Project | undefined>

    getByUserID(userid: string): Observable<ItemList<Project>>

    getFilteredList(q: Query<ProjectQuery>): Observable<ItemList<Project>>

    getAllProjects(): Observable<ItemList<Project>>

    add(e: ProjectDTO): Observable<Project | undefined>

    update(e: ProjectDTO): Observable<Project | undefined>

    delete(id: string): Observable<boolean>

    downloadResults(projectID: string): Observable<File | undefined>

    getFilteredListAnalysis(q: Query<ProjectQuery>): Observable<ItemList<ProjectAnalysis>>
}

export class ProjectApi implements IProjectApi {
    private _container!: Container
    private _httpClient!: IHTTPClient
    private _url!: string
    private _statusService!: IStatusService

    init(c: Container) {
        this._container = c
        this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
        this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
        this._url = (this._container.config as ProjectContainerConfig).moduleFullUrl
    }

    add(e: ProjectDTO): Observable<Project | undefined> {
        return this._httpClient.post<Project>({url: this._url, body: e}).pipe(
            map<ProjectDTO, Project>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(undefined)
            })
        )
    }

    delete(id: string): Observable<boolean> {
        return this._httpClient.delete({url: this._url + '/' + id}).pipe(
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(false)
            })
        )
    }

    getByID(id: string): Observable<Project | undefined> {
        return this._httpClient.get<Project>({url: `${this._url}/${id}`}).pipe(
            map<ProjectDTO, Project>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(undefined)
            })
        )
    }
    
    getByUserID(userid: string): Observable<ItemList<Project>> {
        return this._httpClient.get<ItemList<Project>>({url:`${this._url}/byuserid/${userid}`}).pipe(
            map<ItemList<ProjectDTO>, ItemList<Project>>((dto) => {
                const itemList = emptyList<Project>()
                itemList.count = dto.count
                itemList.items = dto.items.map((d) => toModel(d))
                return itemList
            }),
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(emptyList<Project>())
            })
        )
    }

    getFilteredList(q: Query<ProjectQuery>): Observable<ItemList<Project>> {
        return this._httpClient.get<ItemList<Project>>({url: prepareURL(this._url, q)}).pipe(
            map<ItemList<ProjectDTO>, ItemList<Project>>((dto) => {
                const itemList = emptyList<Project>()
                itemList.count = dto.count
                itemList.items = dto.items.map((d) => toModel(d))
                return itemList
            }),
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(emptyList<Project>())
            })
        )
    }

    getAllProjects(): Observable<ItemList<Project>> {
        return this._httpClient.post<ItemList<Project>>({url: `${this._url}/getAllProjects`}).pipe(
            map<ItemList<ProjectDTO>, ItemList<Project>>((dto) => {
                const itemList = emptyList<Project>()
                itemList.count = dto.count
                itemList.items = dto.items.map((d) => toModel(d))
                return itemList
            }),
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(emptyList<Project>())
            })
        )
    }

    update(e: ProjectDTO): Observable<Project | undefined> {
        return this._httpClient.put<Project>({url: this._url, body: e}).pipe(
            map<ProjectDTO, Project>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(undefined)
            })
        )
    }

    downloadResults(projectID: string): Observable<File | undefined> {
        return this._httpClient.get<File>({ url: `${this._url}/downloadResults/${projectID}` }).pipe(
          catchError((err) => {
            this._statusService.sendStatus({ variant: 'error', error: err });
            return of(undefined);
          })
        );
    }

    getFilteredListAnalysis(q: Query<ProjectQuery>): Observable<ItemList<ProjectAnalysis>> {
        return this._httpClient.get<ItemList<ProjectAnalysis>>({url: prepareURL(this._url+"/list/foranalysis", q)}).pipe(
            map<ItemList<ProjectAnalysis>, ItemList<ProjectAnalysis>>((dto) => {
                const itemList = emptyList<ProjectAnalysis>()
                itemList.count = dto.count
                itemList.items = dto.items
                return itemList
            }),
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(emptyList<ProjectAnalysis>())
            })
        )
    }
}
