import {RouteProps} from "../../routes/AppRouter";
import {Title} from "../../components/common/Title";
import {PatientsPGDTable} from "../../features/patients";

export const TablePGD = (props: RouteProps) => {
    return(
        <>
            <Title color={props.color} title={props.title || ''} />
            <PatientsPGDTable />
        </>
    )
}