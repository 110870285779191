import {Individuals} from "../../individuals/enums/Individuals";


export interface MutationsPositionDTO {
    id: string
    position: number
    chromosome: string
    role: Individuals
    embryo: number
    a1Word: string
    a2Word: string
    a1Color: string
    a2Color: string
    projectID: string
}

export class MutationsPosition {
    private _id: string
    private _position: number
    private _chromosome: string
    private _role: Individuals
    private _embryo: number
    private _a1Word: string
    private _a2Word: string
    private _a1Color: string
    private _a2Color: string
    private _projectID: string

    constructor(p: MutationsPositionDTO) {
        this._id = p.id
        this._position = p.position
        this._chromosome = p.chromosome
        this._role = p.role
        this._embryo = p.embryo
        this._a1Word = p.a1Word
        this._a2Word = p.a2Word
        this._a1Color = p.a1Color
        this._a2Color = p.a2Color
        this._projectID = p.projectID
    }

    get id(): string {
        return this._id
    }

    get position(): number {
        return this._position
    }

    get chromosome(): string {
        return this._chromosome
    }

    get role(): Individuals {
        return this._role
    }

    get embryo(): number {
        return this._embryo
    }

    get a1Word(): string {
        return this._a1Word
    }

    get a2Word(): string {
        return this._a2Word
    }

    get a1Color(): string {
        return this._a1Color
    }

    get a2Color(): string {
        return this._a2Color
    }

    set id(value: string) {
        this._id = value
    }

    set position(value: number) {
        this._position = value
    }

    set role(value: Individuals) {
        this._role = value
    }

    set embryo(value: number) {
        this._embryo = value
    }

    set a1Word(value: string) {
        this._a1Word = value
    }

    set a2Word(value: string) {
        this._a2Word = value
    }

    set a1Color(value: string) {
        this._a1Color = value
    }

    set a2Color(value: string) {
        this._a2Color = value
    }

    get projectID(): string {
        return this._projectID
    }

    toDTO(): MutationsPositionDTO {
        return {
            id: this.id,
            chromosome: this.chromosome,
            position: this.position,
            role: this.role,
            embryo: this.embryo,
            a1Word: this.a1Word,
            a2Word: this.a2Word,
            a1Color: this.a1Color,
            a2Color: this.a2Color,
            projectID: this.projectID
        }
    }
}

export function toModel(p: MutationsPositionDTO): MutationsPosition {
    return new MutationsPosition(p)
}

export function toDTO(p: MutationsPosition): MutationsPositionDTO {
    return p.toDTO()
}

export function emptyMutationsPosition(): MutationsPosition {
    return new MutationsPosition({
        id: '',
        position: 0,
        chromosome: '',
        role: Individuals.UNKNOWN,
        embryo: 0,
        a1Word: '',
        a2Word: '',
        a1Color: '',
        a2Color: '',
        projectID: ''
    })
}

export interface MutationsPositionQuery {
    position: number
    role: Individuals
    embryo: number
    a1Word: string
    a2Word: string
    a1Color: string
    a2Color: string
}