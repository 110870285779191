import { COLOR_CLINICS, COLOR_PRIMARY } from '../../routes/color-constants'
import { AppTable, Field } from '../../components/table'
import React, { useEffect, useState } from 'react'
import { Actions, Pager, Sort } from '../../components/table/types'
import editIcon from '../../assets/table-icons/edit-icon.svg'
import deleteIcon from '../../assets/table-icons/delete-icon.svg'
import { useTranslation } from 'react-i18next'
import { SortParam } from '../../common/api/Query'
import { navigate } from '@reach/router'
import { URL_PROYECT } from '../../routes/routes-constants'
import seeIcon from '../../assets/table-icons/see-icon.svg'
import { Box, Button, Modal } from '@material-ui/core'
import { useStyles } from '../matchings/Matching.styles'
import { Permission } from '../../common/enums/Permissions'
import { getAuthContainer } from '../../container/auth-modules'
import { AuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import { FormAction, FormActions } from '../../common/utils/form-generation'
import { commonStyles } from '../../common/styles/Styles'
import { Project } from 'modules/projects/models/Project'

const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)

export const Table = () => {
  const { t } = useTranslation()
  const classes = useStyles({ color: COLOR_PRIMARY })
  const [selectedProject, setSelectedProject] = useState<Project | undefined>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [pager, setPager] = useState<Pager>()
  const [page, setPage] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [items, setItems] = useState<Project[]>([])
  const [sort, setSort] = useState<SortParam<Project>>({
    field: 'name',
    desc: true,
  })
  const styles = commonStyles()

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: itemsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, itemsPerPage])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setItemsPerPage(10)
      return
    }
    setItemsPerPage(Number.parseInt(event.target.value))
  }

  const fields: Field<Project>[] = [
    {
      sortable: true,
      // searchable: true,
      label: t('projectName'),
      name: 'name',
    },
  ]

  const viewProyect = () => console.log("Ver")
  const editProyect = () => console.log("editar")
  const selectProject= () => console.log("seleccionar")
  const deleteProject = () => console.log("eliminar")

  const actions: Actions<Project> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: viewProyect,
        icon: seeIcon,
        label: t('Show'),
      },
      {
        handler: editProyect,
        icon: editIcon,
        label: t('Edit'),
      },
      {
        handler: selectProject,
        icon: deleteIcon,
        label: t('Delete'),
      },
    ],
  }

  const sortable: Sort<Project> = {
    name: sort.field,
    direction: sort.desc ? 'desc' : 'asc',
    handleSort: (field) => {
      setSort({ field: field, desc: sort.field === field ? !sort.desc : true })
      setIsLoading(true)
    },
  }

  const createProyect = () => navigate(URL_PROYECT.replace(':id', 'create'))

  const handleDelete = () => {
    setOpen(false)
    deleteProject()
  }

  const closeModal = () => {
    setOpen(false)
    setSelectedProject(undefined)

  }

  const actionsDelete: FormAction[] = [
    {
      label: t('close'),
      handleAction: closeModal,
    },
  ]
  
  return (
    <>
      <Box justifyContent={'space-between'} mb={2}>
        {authService.userCan(Permission.editClinics) && (
          <Button
            className={classes.button}
            variant={'contained'}
            onClick={createProyect}
            style={{ float: 'right' }}>
            {t('create')}
          </Button>
        )}
      </Box>
      <AppTable
        styleHeader={{ color: COLOR_CLINICS }}
        actions={actions}
        fields={fields}
        items={items}
        rowKeyField={'id'}
        pager={pager}
        // search={search}
        sort={sortable}
      />
      <Modal open={open}>
        <Box className={styles.modal}>
          <form onSubmit={handleDelete}>
            <Box mt={2} textAlign={'justify'}>
              {t('projectConfirmationMessage')}
            </Box>
            <FormActions actions={actionsDelete} message={t('confirm')} />
          </form>
        </Box>
      </Modal>
    </>
  )
}
