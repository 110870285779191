import { USERS_MODULE } from '../modules/users'
import { CLINICS_MODULE } from '../modules/clinics'
import { AUTHS_MODULE } from '../modules/auth'
import { TESTS_MODULE } from '../modules/tests'
import { PATIENT_PARAMS_MODULE, PATIENTS_MODULE } from '../modules/patients'
import { FILES_MODULE } from '../modules/files'
import { SCRIPTS_MODULE } from '../modules/scripts'
import { SCRIPT_EXECUTIONS_MODULE } from '../modules/script-executions'
import { ANALYSIS_MODULE } from '../modules/analysis'
import { RECEPTIVENESS_FILES_MODULE } from '../modules/receptiveness-files/container'
import { INDIVIDUAL_MODULE } from 'modules/individuals'
import { EMBRYO_MODULE } from 'modules/embryos'
import {PATIENTS_PGD_MODULE} from "../modules/patients/container";
import { PROJECT_MODULE } from 'modules/projects'
import {MUTATIONS_POSITION_MODULE} from "../modules/mutationsPosition";
import {WORDS_MODULE} from "../modules/word";

const apiURL = process.env.REACT_APP_BACKEND_URL

export const moduleURLs = {
  [AUTHS_MODULE]: `${apiURL}`,
  [USERS_MODULE]: `${apiURL}/users`,
  [CLINICS_MODULE]: `${apiURL}/clinics`,
  [TESTS_MODULE]: `${apiURL}/tests`,
  [PATIENTS_MODULE]: `${apiURL}/patients`,
  [PATIENTS_PGD_MODULE] : `${apiURL}/patients-pgd`,
  [PATIENT_PARAMS_MODULE]: `${apiURL}/patient-params`,
  [FILES_MODULE]: `${apiURL}/files`,
  [SCRIPT_EXECUTIONS_MODULE]: `${apiURL}/script-executions`,
  [SCRIPTS_MODULE]: `${apiURL}/scripts`,
  [ANALYSIS_MODULE]: `${apiURL}/analysis`,
  [RECEPTIVENESS_FILES_MODULE]: `${apiURL}/receptivenessfiles`,
  [INDIVIDUAL_MODULE]: `${apiURL}/individuals`,
  [EMBRYO_MODULE]: `${apiURL}/embryos`,
  [PROJECT_MODULE]: `${apiURL}/projects`,
  [MUTATIONS_POSITION_MODULE]: `${apiURL}/mutationsposition`,
  [WORDS_MODULE]: `${apiURL}/word`,
}
