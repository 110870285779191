import { ResultPatientData, ResultPatientDataDTO } from '../../script-executions/models/ResultPatientData'

export interface AnalysisInmonucompatibilityDTO {
  summaryID: string
  sequenceID: string
  alignmentID: string
  cobertureID: string
  clinicID: string
  zipFileID: string | undefined
  patients: ResultPatientDataDTO[]
}

export class AnalysisInmonucompatibility {
  private readonly _summaryID: string
  private readonly _sequenceID: string
  private readonly _alignmentID: string
  private readonly _cobertureID: string
  private readonly _clinicID: string
  private readonly _zipFileID: string | undefined
  private readonly _patients: ResultPatientData[]

  constructor(p: AnalysisInmonucompatibilityDTO) {
    this._summaryID = p.summaryID
    this._sequenceID = p.sequenceID
    this._alignmentID = p.alignmentID
    this._cobertureID = p.cobertureID
    this._clinicID = p.clinicID
    this._zipFileID = p.zipFileID
    this._patients = p.patients.map((p) => new ResultPatientData(p))
  }

  get summaryID(): string {
    return this._summaryID
  }

  get sequenceID(): string {
    return this._sequenceID
  }

  get clinicID(): string {
    return this._clinicID
  }

  get zipFileID(): string | undefined {
    return this._zipFileID
  }

  get patients(): ResultPatientData[] {
    return this._patients
  }

  get alignmentID(): string {
    return this._alignmentID
  }

  get cobertureID(): string {
    return this._cobertureID
  }

  toDTO(): AnalysisInmonucompatibilityDTO {
    return {
      summaryID: this.summaryID,
      sequenceID: this.sequenceID,
      alignmentID: this.alignmentID,
      cobertureID: this.cobertureID,
      clinicID: this.clinicID,
      zipFileID: this.zipFileID,
      patients: this.patients.map((p) => p.toDTO()),
    }
  }
}

export function toModel(d: AnalysisInmonucompatibilityDTO): AnalysisInmonucompatibility {
  return new AnalysisInmonucompatibility(d)
}
