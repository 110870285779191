export enum Status {
    NonCarrier = 0,
    Carrier,
    Affected,
    Null
}

export enum FinalStatus {
    NonCarrier = 0,
    CarrierFromMother,
    CarrierFromFather,
    Affected,
    Null
}

export const statusTitle = (): Record<Status, string> => ({
    [Status.NonCarrier]: "Non Carrier",
    [Status.Carrier]: "Carrier",
    [Status.Affected]: "Affected",
    [Status.Null]: "Unknown"
})

export const finalStatusTitle = (): Record<FinalStatus, string> => ({
    [FinalStatus.NonCarrier]: "Non Carrier",
    [FinalStatus.CarrierFromMother]: "Carrier from Mother",
    [FinalStatus.CarrierFromFather]: "Carrier from Father",
    [FinalStatus.Affected]: "Affected",
    [FinalStatus.Null]: "Unknown"
})
