export enum Individuals {
    FEMALE_FATHER = 0,
    FEMALE,
    MALE,
    CHILD,
    FEMALE_MOTHER,
    MALE_MOTHER,
    MALE_FATHER,
    UNKNOWN
}

export const individualsTitle = (): Record<Individuals, string> => ({
    [Individuals.FEMALE_FATHER]: "FEMALE-FATHER",
    [Individuals.FEMALE]: "FEMALE",
    [Individuals.MALE]: "MALE",
    [Individuals.CHILD]: "CHILD",
    [Individuals.FEMALE_MOTHER]: "FEMALE-MOTHER",
    [Individuals.MALE_MOTHER]: "MALE-MOTHER",
    [Individuals.MALE_FATHER]: "MALE-FATHER",
    [Individuals.UNKNOWN]: ""
})
