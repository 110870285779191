import {Container, IInit} from "../../../common/container/Container";
import {Word, WordDTO} from "../models/Word";
import {Observable, of} from "rxjs";
import {HTTP_CLIENT_KEY, IHTTPClient} from "../../../common/api/HTTPClient";
import {IStatusService} from "../../../common/status/StatusService";
import {WordContainerConfig} from "../container";
import {STATUS_SERVICE_KEY} from "../../../container/app";
import {catchError} from "rxjs/operators";

export interface IWordApi extends IInit {
    getByID(id: string): Observable<Word | undefined>

    update(e: WordDTO): Observable<WordDTO | undefined>
}

export class WordApi implements IWordApi {
    private _container!: Container
    private _httpClient!: IHTTPClient
    private _url!: string
    private _statusService!: IStatusService

    init(c: Container) {
        this._container = c
        this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
        this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
        this._url = (this._container.config as WordContainerConfig).moduleFullUrl
    }

    getByID(id: string): Observable<Word | undefined> {
        return this._httpClient.get<Word>({url: this._url + '/' + id}).pipe(
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(undefined)
            })
        )
    }

    update(e: WordDTO): Observable<WordDTO | undefined> {
        return this._httpClient.put<WordDTO>({url: this._url, body: e}).pipe(
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(undefined)
            })
        )
    }
}