import { v4 as uuidv4 } from 'uuid'

export interface ScriptDTO {
  id: string
  version: number
  script: string
  genes: string
  knn: string
  lda: string
  lda2: string
  rf: string
  signals: string
  slda: string
  svml: string
  svmr: string
}

export class Script {
  private readonly _id: string
  private _version: number
  private _script: string
  private _genes: string
  private _knn: string
  private _lda: string
  private _lda2: string
  private _rf: string
  private _signals: string
  private _slda: string
  private _svml: string
  private _svmr: string

  constructor(p: ScriptDTO) {
    this._id = p.id
    this._version = p.version
    this._script = p.script
    this._genes = p.genes
    this._knn = p.knn
    this._lda = p.lda
    this._lda2 = p.lda2
    this._rf = p.rf
    this._signals = p.signals
    this._slda = p.slda
    this._svml = p.svml
    this._svmr = p.svmr
  }

  get id(): string {
    return this._id
  }

  get version(): number {
    return this._version
  }

  set version(value: number) {
    this._version = value
  }

  get script(): string {
    return this._script
  }

  set script(value: string) {
    this._script = value
  }

  get genes(): string {
    return this._genes
  }

  set genes(value: string) {
    this._genes = value
  }

  get knn(): string {
    return this._knn
  }

  set knn(value: string) {
    this._knn = value
  }

  get lda(): string {
    return this._lda
  }

  set lda(value: string) {
    this._lda = value
  }

  get lda2(): string {
    return this._lda2
  }

  set lda2(value: string) {
    this._lda2 = value
  }

  get rf(): string {
    return this._rf
  }

  set rf(value: string) {
    this._rf = value
  }

  get signals(): string {
    return this._signals
  }

  set signals(value: string) {
    this._signals = value
  }

  get slda(): string {
    return this._slda
  }

  set slda(value: string) {
    this._slda = value
  }

  get svml(): string {
    return this._svml
  }

  set svml(value: string) {
    this._svml = value
  }

  get svmr(): string {
    return this._svmr
  }

  set svmr(value: string) {
    this._svmr = value
  }

  toDTO(): ScriptDTO {
    return {
      id: this.id,
      version: this.version,
      script: this.script,
      genes: this.genes,
      knn: this.knn,
      lda: this.lda,
      lda2: this.lda2,
      rf: this.rf,
      signals: this.signals,
      slda: this.slda,
      svml: this.svml,
      svmr: this.svmr,
    }
  }
}

export function toModel(d: ScriptDTO): Script {
  return new Script(d)
}

export function emptyScriptDTO(type: string): ScriptDTO {
  return {
    genes: '',
    id: uuidv4(),
    knn: '',
    lda: '',
    lda2: '',
    rf: '',
    script: '',
    signals: '',
    slda: '',
    svml: '',
    svmr: '',
    version: 0,
  }
}
