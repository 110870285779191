import { Box, Button, Grid, TextField } from '@material-ui/core'
import { EntitySelect } from '../common/EntitySelect'
import React, { ChangeEvent, useEffect, useState } from 'react'
import * as XLSX from 'xlsx'
import { ErrorRecord } from '../../common/utils/form-generation/types'
import { Clinic, ClinicQuery } from '../../modules/clinics/models/Clinic'
import { Query, QueryParam } from '../../common/api/Query'
import { getClinicContainer } from '../../container/clinic-modules'
import { ClinicService } from '../../modules/clinics/services/ClinicService'
import { CLINIC_SERVICE_KEY } from '../../modules/clinics'
import { getAuthContainer } from '../../container/auth-modules'
import { AuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import { ResultPatientDataDTO } from '../../modules/script-executions/models/ResultPatientData'
import { useTranslation } from 'react-i18next'
import { dataToBase642 } from '../../common/files/file'
import { v4 as uuidv4 } from 'uuid'
import { forkJoin, lastValueFrom } from 'rxjs'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { FileService } from '../../modules/files/services/FileService'
import { getFileContainer } from '../../container/file-module'
import { FileType } from './Form'
import { getPatientContainer } from '../../container/patient-module'
import { PatientService } from '../../modules/patients/services/PatientService'
import { PATIENT_SERVICE_KEY } from '../../modules/patients'
import { PatientQuery } from '../../modules/patients/models/PatientReceptiveness'
import { Permission } from '../../common/enums/Permissions'
import CircularProgress from '@mui/material/CircularProgress'

type CheckFile = {
  name: string
  setState: (f: File | undefined) => void
}

type FirstStep = {
  sampleRelation: string
  secuence: string
  alignment: string
  amplicon: string
  clinicID: string
}

type FirstStepProps = {
  increaseStep: () => void
  valuesToTable: (list: ResultPatientDataDTO[]) => void
  files: (ids: FileType[]) => void
  selectedClinic: (id: string) => void
}

const maxSampleNames = 20
const clinicService = getClinicContainer().get<ClinicService>(CLINIC_SERVICE_KEY)
const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)
const fileService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)
const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)

export const FirstStep = (props: FirstStepProps) => {
  const { t } = useTranslation()
  const [sampleRelation, setSampleRelation] = useState<File>()
  const [secuence, setSecuence] = useState<File>()
  const [alignment, setAlignment] = useState<File>()
  const [amplicon, setAmplicon] = useState<File>()
  const [clinics, setClinics] = useState<Clinic[]>([])
  const [selectedClinic, setSelectedClinic] = useState<string>(
    authService.get().clinics.length === 1 ? authService.get().clinics[0] : ''
  )
  const [errors, setErrors] = useState<ErrorRecord<FirstStep>>({} as ErrorRecord<FirstStep>)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    const query = []
    if (!authService.get().permissions.includes(Permission.viewAll)) {
      query.push(new QueryParam<ClinicQuery>('ids', authService.get().clinics))
    } else {
      clinicService.getAllClinics().subscribe((res) => setClinics(res.items))
    }
    clinicService
      .getFilteredList(
        new Query({
          query,
          sort: [{ field: 'name' }],
        })
      )
      .subscribe((res) => setClinics(res.items))
  }, [])

  useEffect(() => {
    selectedClinic && props.selectedClinic(selectedClinic)
  }, [selectedClinic])

  const handleFileInput = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    setState: (f: File) => void
  ) => {
    const files = (event.target as HTMLInputElement).files
    files && files.length && setState(files[0])
  }

  const validate = async () => {
    const errorCopy = {} as ErrorRecord<FirstStep>
    const msg = await validateSample()

    if (msg) {
      errorCopy['sampleRelation'] = t(msg)
    }

    if (!secuence?.name.endsWith('.fastq')) {
      errorCopy['secuence'] = t('fileNotValidError')
    }

    if (!alignment?.name.includes('.bam')) {
      errorCopy['alignment'] = t('fileNotValidError')
    }

    if (!amplicon?.name.endsWith('.xls')) {
      errorCopy['amplicon'] = t('fileNotValidError')
    }

    if (!selectedClinic) {
      errorCopy['clinicID'] = t('noClinic')
    }

    setErrors(errorCopy)

    if (Object.keys(errorCopy).length) {
      return
    }

    await setData()
  }

  const setData = async () => {
    if (!sampleRelation || !amplicon || !alignment || !secuence) {
      return;
    }
  
    setIsLoading(true); 
  
    try {
      const files = [sampleRelation, amplicon, alignment, secuence];
      const ids: string[] = [];
      const data: string[] = [];
  
      for (const f of files) {
        ids.push(uuidv4());
  
        const { base64, error } = await dataToBase642(f);
        if (error == null) {
          data.push(base64);
        } else {
          const errorCopy: ErrorRecord<FirstStep> = {} as ErrorRecord<FirstStep>;
          errorCopy['alignment'] = t('fileNotValidError');
          setErrors(errorCopy);
          setIsLoading(false)
          return
        }
      }
  
      await new Promise<void>((resolve, reject) => {
        forkJoin(
          files.map((f, i) =>
            fileService.add({
              id: ids[i],
              name: f.name,
              data: data[i],
              size: f.size,
              mimeType: f.type,
              extension: f.type.split('/')[1],
              ownerID: authService.get().id,
            })
          )
        ).subscribe({
          next: () => {},
          error: reject,
          complete: () => {
            props.files(files.map((f, i) => ({ id: ids[i], name: f.name })));
            resolve();
          },
        });
      });
      setIsLoading(false); 
      props.increaseStep()
    } catch (error) {
      console.error('An error occurred:', error);
    } 
  };  

  const validateSample = async (): Promise<string> => {
    if (!sampleRelation?.name.endsWith('bc_summary.xls')) {
      return 'fileNotValidError'
    }

    const workbook = XLSX.read(await sampleRelation.arrayBuffer(), { type: 'binary' })
    const firstSheet = workbook.SheetNames[0]

    const excelRows = XLSX.utils.sheet_to_json(
      workbook.Sheets[firstSheet]
    ) as ResultPatientDataDTO[]
    const values = excelRows.map((e) => ({
      barcodeID: Object.values(e)[0],
      sampleName: '' + Object.values(e)[1],
      biopsyMethod: '',
      biopsyDate: undefined,
      biopsyNumber: 1,
      cycleType: 100,
      p4Injection: undefined,
      hcgInjection: undefined,
      lhDate: undefined,
      pPlus: '',
      lhPlus: '',
      hcgPlus: '',
    }))
    if (
      !values.length ||
      values.some((item, i) => values.findIndex((v) => v.sampleName === item.sampleName) !== i)
    ) {
      return 'duplicatedSampleNames'
    }

    if (values.length > maxSampleNames) {
      return 'maxSampleNamesError'
    }

    const clinic = clinics.find((c) => c.id === selectedClinic)
    if (clinic && values.length > clinic.remainingCredits) {
      return 'insufficientCredits'
    }

    const sampleNames = values.map((v) => v.sampleName)
    const res = await lastValueFrom(
      patientService.getFilteredList(
        new Query({
          pager: { offset: 0, limit: sampleNames.length },
          query: [
            new QueryParam<PatientQuery>('sampleNames', sampleNames),
            new QueryParam<PatientQuery>('IDClinic', selectedClinic),
          ],
        })
      )
    )

    if (res.items.length !== sampleNames.length) {
      return (
        t('nonexistentSampleNames') +
        ': ' +
        sampleNames.filter((s) => !res.items.find((p) => p.sampleName === s)).join(', ')
      )
    }

    props.valuesToTable(values)

    return ''
  }

  const checkFiles: CheckFile[] = [
    { name: 'sampleRelation', setState: setSampleRelation },
    { name: 'secuence', setState: setSecuence },
    { name: 'alignment', setState: setAlignment },
    { name: 'amplicon', setState: setAmplicon },
  ]

  return (
    <>
      {!isLoading ? (
        <>
          {checkFiles.map((f) => (
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant={'outlined'}
                error={errors[f.name as keyof FirstStep] !== undefined}
                id={f.name}
                onChange={(event) => handleFileInput(event, f.setState)}
                type={'file'}
                label={t(f.name) + ' *'}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={errors[f.name as keyof FirstStep]}
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <EntitySelect
              name={'name'}
              value={selectedClinic}
              error={errors['clinicID']}
              options={clinics}
              onChange={(value) => value?.id && setSelectedClinic(value.id)}
              label={t('clinic') + ' *'}
              pk={'id'}
              disabled={authService.get().clinics.length < 2}
            />
          </Grid>
          <Grid item xs={12}>
            <Box mt={3}>
              <Button onClick={validate}>{t('continue')}</Button>
            </Box>
          </Grid>
        </>
      ) : (
        <Box style={{justifyContent: "center", width: "100%"}}>
            <CircularProgress />
          </Box>
      )}
    </>
  )
}
