import {makeStyles, Theme} from '@material-ui/core/styles'
import {createStyles} from '@material-ui/core'
import {COLOR_PRIMARY} from '../../routes/color-constants'

export const commonStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            position: 'absolute',
            width: 600,
            backgroundColor: theme.palette.background.paper,
            borderRadius: '4px',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 0),
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
        modalIGV: {
            position: 'absolute',
            width: 1000,
            backgroundColor: theme.palette.background.paper,
            borderRadius: '4px',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 0),
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            overflowY: "auto",
            maxHeight: '80vh', // Limita la altura al 80% de la ventana para que aparezca el scroll si es necesario

        },
        toggle: {
            width: 50,
            '& .Mui-checked': {
                color: COLOR_PRIMARY,
            },
            '& .Mui-checked:hover': {
                backgroundColor: 'rgba(204, 206, 207, 0.2)',
            },
            '& .Mui-checked + .MuiSwitch-track': {
                backgroundColor: '#4e7d9c',
            },
            '& .MuiSwitch-track': {
                backgroundColor: '#cccecf',
                color: '#FFAA54',
            },
        },
        toggleLarge: {
            width: 400,
            '& .Mui-checked': {
                color: COLOR_PRIMARY,
            },
            '& .Mui-checked:hover': {
                backgroundColor: 'rgba(204, 206, 207, 0.2)',
            },
            '& .Mui-checked + .MuiSwitch-track': {
                backgroundColor: '#4e7d9c',
            },
            '& .MuiSwitch-track': {
                backgroundColor: '#cccecf',
                color: '#FFAA54',
            },
        },
        button: {
            color: theme.palette.common.white,
            background: theme.palette.primary.main,
            border: 0,
        },
        required: {
            content: '*',
            color: 'red',

        }
    }),
)