import { PatientReceptiveness, PatientReceptivenessDTO, PatientQuery } from '../models/PatientReceptiveness'
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { IPatientApi } from '../api/PatientApi'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import {PatientPGD, PatientPGDDTO} from "../models/PatientPGD";

type Props = {
  apiKey: symbol
}

export interface IPatientService extends IInit {
  getByID(id: string): Observable<PatientReceptiveness | undefined>
  getByIDPGD(id: string): Observable<PatientPGD | undefined>
  getBySampleName(sampleName: string): Observable<PatientReceptiveness | undefined>
  getFilteredList(q: Query<PatientQuery>): Observable<ItemList<PatientReceptiveness>>
  add(e: PatientReceptivenessDTO): Observable<PatientReceptiveness | undefined>
  addPGD(e: PatientPGDDTO): Observable<PatientPGD | undefined>
  update(e: PatientReceptivenessDTO): Observable<PatientReceptiveness | undefined>
  updatePGD(e: PatientPGDDTO): Observable<PatientPGD | undefined>
  delete(id: string): Observable<boolean>
  getFilteredListPGD(q: Query<PatientQuery>): Observable<ItemList<PatientPGD>>
}

export class PatientService implements IPatientService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IPatientApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IPatientApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getByID(id: string): Observable<PatientReceptiveness | undefined> {
    return this._api.getByID(id)
  }

  getByIDPGD(id: string): Observable<PatientPGD | undefined> {
    return this._api.getByIDPGD(id)
  }

  getBySampleName(sampleName: string): Observable<PatientReceptiveness | undefined>{
    return this._api.getBySampleName(sampleName)
  }

  getFilteredList(q: Query<PatientQuery>): Observable<ItemList<PatientReceptiveness>> {
    return this._api.getFilteredList(q).pipe()
  }

  add(e: PatientReceptivenessDTO): Observable<PatientReceptiveness | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  update(e: PatientReceptivenessDTO): Observable<PatientReceptiveness | undefined> {
    return this._api.update(e)
  }

  /* PGD */


  getFilteredListPGD(q: Query<PatientQuery>): Observable<ItemList<PatientPGD>> {
    return this._api.getFilteredListPGD(q).pipe()
  }

  addPGD(e: PatientPGDDTO): Observable<PatientPGD | undefined> {
    return this._api.addPGD(e)
  }

  updatePGD(e: PatientPGDDTO): Observable<PatientPGD | undefined> {
    return this._api.updatePGD(e)
  }
}
