import {RouteComponentProps, Router} from '@reach/router'
import {AuthService} from 'modules/auth/services/AuthService'
import React, {useEffect, useState} from 'react'
import {
    URL_CHANGE_PASSWORD,
    URL_CLINIC,
    URL_CLINIC_SHOW,
    URL_CLINICS,
    URL_PATIENT,
    URL_PATIENT_SHOW,
    URL_PATIENTS, URL_PATIENTS_PGD,
    URL_PGD_TEST,
    URL_PATIENT_PGD,
    URL_PROFILE_USER,
    URL_RECEPTIVENESS,
    URL_RECEPTIVENESS_TABLE,
    URL_RECOVER_PASSWORD,
    URL_REGISTER,
    URL_SCRIPT,
    URL_TEST,
    URL_USER,
    URL_BEDFILES,
    URL_USERS,
    URL_PROYECTS_TABLE,
    URL_PROYECT,
    URL_PATIENT_PGD_SHOW,
    URL_SHOW_PROYECT,
    URL_IMMUNOCOM
} from './routes-constants'
import {
    TITLE_BEDFILES,
    TITLE_CLINIC,
    TITLE_PATIENTS,
    TITLE_PROFILE_USER,
    TITLE_RECEPTIVENESS,
    TITLE_SCRIPT,
    TITLE_TESTS,
    TITLE_USERS,
    TITLE_PROYECTS,
    TITLE_PATIENTS_RECEPTIVENESS,
    TITLE_PATIENTS_PGD,
    TITLE_IMMUNOCOM
} from './title-constants'
import {COLOR_CLINICS, COLOR_PATIENTS, COLOR_PRIMARY, COLOR_RESULTS, COLOR_SETTINGS} from './color-constants'
import {Register} from '../pages/login/Register'
import {LoggedUser} from '../modules/auth/models/LoggedUser'
import {getAuthContainer} from '../container/auth-modules'
import {AUTH_SERVICE_KEY} from '../modules/auth'
import {RecoverPassword} from '../pages/login/RecoverPassword'
import {ChangePassword} from '../pages/login/ChangePassword'
import {ClinicDetail, ClinicForm, ClinicsTable} from '../pages/clinics'
import {PatientDetail, PatientForm, PatientsPGDTable, PatientsTable, PatientPGDDetail} from '../pages/patients'
import {UserProfile} from '../pages/users/UserProfile'
import {UserForm, UsersTable} from '../pages/users'
import {Permission} from '../common/enums/Permissions'
import {TestForm} from '../pages/tests'
import {useTranslation} from 'react-i18next'
import {ReceptivenessForm, ReceptivenessTable} from '../pages/receptiveness'
import {ScriptForm} from '../pages/scripts'
import { TestFormPGD } from 'pages/pgdTests'
import { Bedfiles } from 'pages/scripts/Bedfiles'
import { PatientPGDForm } from 'pages/patients/'
import { ProjectsTable } from 'pages/projects'
import { ProjectsForm } from 'pages/projects'
import { ProjectsDetail } from 'pages/projects'
import { ImmunocomForm } from '../pages/immunocom'

export type RouteProps = {
    title?: string
    subtitle?: string
    color?: string
    id?: string
} & RouteComponentProps

const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)

export const AppRouter: React.FC = () => {
    const {t} = useTranslation()

    const [user, setUser] = useState<LoggedUser>(authService.get())

    useEffect(() => {
        authService.getObservable().subscribe((u: LoggedUser) => {
            setUser(u)
        })
    }, [user])

    const publicRoutes = (
        <>
            <Register path={URL_REGISTER}/>
            <RecoverPassword path={URL_RECOVER_PASSWORD}/>
            <ChangePassword path={URL_CHANGE_PASSWORD}/>
        </>
    )

    const generalRoutes = (
        <>
            {authService.userCan(Permission.viewUsers) && (
                <UsersTable path={URL_USERS} color={COLOR_SETTINGS}
                            title={window.navigator.language === 'es-ES' ? t(TITLE_USERS) : 'Users'}/>
            )}
            {authService.userCan(Permission.editUsers) && (
                <UserForm path={URL_USER} color={COLOR_SETTINGS}
                          title={window.navigator.language === 'es-ES' ? t(TITLE_USERS) : 'Users'}/>
            )}
            {authService.userCan(Permission.viewClinics) && (
                <ClinicsTable path={URL_CLINICS} color={COLOR_CLINICS}
                              title={user.language === 'es' ? t(TITLE_CLINIC) : 'Laboratories'}/>
            )}
            {authService.userCan(Permission.editClinics) && (
                <ClinicForm path={URL_CLINIC} color={COLOR_CLINICS}
                            title={user.language === 'es' ? t(TITLE_CLINIC) : 'Laboratories'}/>
            )}
            {authService.userCan(Permission.viewClinics) && (
                <ClinicDetail path={URL_CLINIC_SHOW} color={COLOR_CLINICS}
                              title={user.language === 'es' ? t(TITLE_CLINIC) : 'Laboratories'}/>
            )}
            {authService.userCan(Permission.viewPatients) && (
                <PatientsTable path={URL_PATIENTS} color={COLOR_PRIMARY}
                               title={user.language === 'es' ? t(TITLE_PATIENTS) + " " +  t(TITLE_PATIENTS_RECEPTIVENESS) : 'Patients Receptivity'}/>
            )}

            {authService.userCan(Permission.viewPatients) && (
                <PatientsPGDTable path={URL_PATIENTS_PGD} color={COLOR_PRIMARY}
                                 title={user.language === 'es' ? t(TITLE_PATIENTS) + " " +  t(TITLE_PATIENTS_PGD): 'Patients PGD-SEQ'}/>
            )}
            {authService.userCan(Permission.viewPatients) && (
                <PatientPGDForm path={URL_PATIENT_PGD} color={COLOR_PRIMARY}
                                 title={user.language === 'es' ? t(TITLE_PATIENTS) + TITLE_PATIENTS_PGD : 'Patients PGD-SEQ'}/>
            )}
            {authService.userCan(Permission.viewPatients) && (
                <PatientPGDDetail path={URL_PATIENT_PGD_SHOW} color={COLOR_PRIMARY}
                                 title={user.language === 'es' ? t(TITLE_PATIENTS) + TITLE_PATIENTS_PGD : 'Patients PGD-SEQ'}/>
            )}
            {authService.userCan(Permission.editPatients) && (
                <PatientForm path={URL_PATIENT} color={COLOR_PATIENTS}
                             title={user.language === 'es' ? t(TITLE_PATIENTS) + " " +  t(TITLE_PATIENTS_RECEPTIVENESS): 'Patients'}/>
            )}
            {authService.userCan(Permission.viewPatients) && (
                <PatientDetail path={URL_PATIENT_SHOW} color={COLOR_PRIMARY}
                               title={user.language === 'es' ? t(TITLE_PATIENTS) : 'Patients'}/>
            )}
            {authService.userCan(Permission.editPatientTests) && (
                <TestForm path={URL_TEST} color={COLOR_SETTINGS} title={t(TITLE_TESTS)}/>
            )}

            <TestFormPGD path={URL_PGD_TEST} color={COLOR_SETTINGS} title={t(TITLE_TESTS)}/>
            
            {authService.userCan(Permission.uploadResults) && (
                <ReceptivenessForm path={URL_RECEPTIVENESS} color={COLOR_RESULTS}
                                   title={user.language === 'es' ? t(TITLE_RECEPTIVENESS) : 'Receptiveness'}/>
            )}

            {authService.userCan(Permission.uploadResults) && (
                <ReceptivenessTable path={URL_RECEPTIVENESS_TABLE} color={COLOR_RESULTS}
                                    title={user.language === 'es' ? t(TITLE_RECEPTIVENESS) : 'Receptiveness'}/>
            )}

    {authService.userCan(Permission.uploadResults) && (
                <ImmunocomForm path={URL_IMMUNOCOM} color={COLOR_RESULTS}
                                   title={user.language === 'es' ? t(TITLE_IMMUNOCOM) : 'inmunocompatibility'}/>
            )}

            <ProjectsTable path={URL_PROYECTS_TABLE} color={COLOR_RESULTS}
                                    title={user.language === 'es' ? t(TITLE_PROYECTS) : 'Proyects'}/>

            <ProjectsForm path={URL_PROYECT} color={COLOR_RESULTS}
                                    title={user.language === 'es' ? t(TITLE_PROYECTS) : 'Proyects'}/>

            <ProjectsDetail path={URL_SHOW_PROYECT} color={COLOR_RESULTS}
                                    title={t('show')}/>
            
            {authService.userCan(Permission.changeScript) && (
                <ScriptForm path={URL_SCRIPT} color={COLOR_SETTINGS} title={t(TITLE_SCRIPT)}/>
            )}
           
            <UserProfile path={URL_PROFILE_USER} color={COLOR_PRIMARY}
                         title={user.language === 'es' ? t(TITLE_PROFILE_USER) : 'Profile'}/>

            <Bedfiles path={URL_BEDFILES} color={COLOR_PRIMARY}
                         title={t(TITLE_BEDFILES)}/>
        </>
    )

    return <Router component={'div'}>{user.id ? generalRoutes : publicRoutes}</Router>
}
