import {emptyList, ItemList} from '../../../common/models/ItemList'
import {Observable, of} from 'rxjs'
import {Container, IInit} from '../../../common/container/Container'
import {HTTP_CLIENT_KEY, IHTTPClient} from '../../../common/api/HTTPClient'
import {IStatusService} from '../../../common/status/StatusService'
import {STATUS_SERVICE_KEY} from '../../../container/app'
import {Query} from '../../../common/api/Query'
import {Embryo, EmbryoDTO, EmbryoQuery, EmbryosGroup, toModel} from '../models/Embryo'
import {EmbryoContainerConfig} from '../container'
import {prepareURL} from '../../../common/api/http-helpers'
import {catchError, map} from 'rxjs/operators'
import {Individual, IndividualDTO} from "../../individuals/models/Individual";

export interface IEmbryoApi extends IInit {
    getByID(id: string): Observable<Embryo | undefined>

    getFilteredList(q: Query<EmbryoQuery>): Observable<ItemList<EmbryoDTO>>

    getEmbryosGroup(): Observable<ItemList<EmbryosGroup>>

    add(e: EmbryoDTO): Observable<any | undefined>

    update(e: EmbryoDTO): Observable<Embryo | undefined>

    updateFile(e: EmbryoDTO): Observable<Embryo | undefined>

    delete(id: string): Observable<boolean>

    runEmbryoSnipetsProcess(projectID: string): Observable<boolean>

    updateEmbryoGroup(e: EmbryosGroup): Observable<EmbryosGroup | undefined>

    updateBamFiles(e: EmbryoDTO): Observable<Embryo | undefined>
}

export class EmbryoApi implements IEmbryoApi {
    private _container!: Container
    private _httpClient!: IHTTPClient
    private _url!: string
    private _statusService!: IStatusService

    init(c: Container) {
        this._container = c
        this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
        this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
        this._url = (this._container.config as EmbryoContainerConfig).moduleFullUrl
    }

    add(e: EmbryoDTO): Observable<any | undefined> {
        return this._httpClient.post<Embryo>({url: this._url, body: e}).pipe(
            map<any, any>((d) => {
                return d
            }),
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(undefined)
            })
        )
    }

    delete(id: string): Observable<boolean> {
        return this._httpClient.delete({url: this._url + '/' + id}).pipe(
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(false)
            })
        )
    }

    getByID(id: string): Observable<Embryo | undefined> {
        return this._httpClient.get<Embryo>({url: `${this._url}/${id}`}).pipe(
            map<EmbryoDTO, Embryo>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(undefined)
            })
        )
    }

    getFilteredList(q: Query<EmbryoQuery>): Observable<ItemList<EmbryoDTO>> {
        return this._httpClient.get<ItemList<EmbryoDTO>>({url: prepareURL(this._url, q)}).pipe(
            map<ItemList<EmbryoDTO>, ItemList<EmbryoDTO>>((dto) => {
                const itemList = emptyList<EmbryoDTO>()
                itemList.count = dto.count
                itemList.items = dto.items
                return itemList
            }),
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(emptyList<Embryo>())
            })
        )
    }

    getEmbryosGroup(): Observable<ItemList<EmbryosGroup>> {
        return this._httpClient.get<ItemList<EmbryosGroup>>({url: this._url + "/embryosgroup/embryosgroup"}).pipe(
            map<ItemList<EmbryosGroup>, ItemList<EmbryosGroup>>((dto) => {
                const itemList = emptyList<EmbryosGroup>()
                itemList.count = dto.count
                itemList.items = dto.items
                return itemList
            }),
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(emptyList<EmbryosGroup>())
            })
        )
    }

    update(e: EmbryoDTO): Observable<Embryo | undefined> {
        return this._httpClient.put<Embryo>({url: this._url, body: e}).pipe(
            map<EmbryoDTO, Embryo>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(undefined)
            })
        )
    }

    updateFile(e: EmbryoDTO): Observable<Embryo | undefined> {
        return this._httpClient.put<Embryo>({url: this._url + "/updateFile", body: e}).pipe(
            map<EmbryoDTO, Embryo>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(undefined)
            })
        )
    }

    runEmbryoSnipetsProcess(projectID: string): Observable<boolean> {
        return this._httpClient.post<boolean>({url: `${this._url}/runEmbryoSnipetsProcess/${projectID}`}).pipe(
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(false)
            })
        )
    }

    updateEmbryoGroup(e: EmbryosGroup): Observable<EmbryosGroup | undefined> {
        return this._httpClient.put<EmbryosGroup>({url: this._url + "/updateEmbryo", body: e}).pipe(
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(undefined)
            })
        )
    }

    updateBamFiles(e: EmbryoDTO): Observable<Embryo | undefined> {
        return this._httpClient.put<Embryo>({url: this._url + "/updatebamfiles", body: e}).pipe(
            map<EmbryoDTO, Embryo>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(undefined)
            })
        )
    }
}
