import { v4 as uuidv4 } from 'uuid'
import { File as F } from 'modules/files/models/File'
import {Individuals} from "../enums/Individuals";
import {FinalStatus, Status} from "../enums/Status";

export interface IndividualDTO {
  id: string
  individual: Individuals
  fileName: string
  sampleName: string
  date: Date
  status: Status
  finalStatus: FinalStatus
  file: F | undefined
  informativityID: string
  mutation: string
  baiFileID: string
  bimFileID: string
}

export interface Informativity {
  id: string
  name: string
}

export class Individual {
  private _id: string
  private _individual: Individuals
  private _fileName: string
  private _sampleName: string
  private _date: Date
  private _status: Status
  private _finalStatus: FinalStatus
  private _file: F | undefined
  private _informativityID: string
  private _mutation: string
  private _baiFileID: string
  private _bimFileID: string

  constructor(p: IndividualDTO) {
    this._id = p.id
    this._individual = p.individual
    this._fileName = p.fileName
    this._sampleName = p.sampleName
    this._date = p.date
    this._status = p.status
    this._finalStatus = p.finalStatus
    this._file = p.file
    this._informativityID = p.informativityID
    this._mutation = p.mutation
    this._baiFileID = p.baiFileID
    this._bimFileID = p.bimFileID
  }

  get id(): string {
    return this._id
  }
  
  get informativityID(): string {
    return this._informativityID
  }

  set id(id: string) {
    this._id = id
  }

  get individual(): Individuals {
    return this._individual
  }
  
  set individual(individual: Individuals) {
    this._individual = individual
  }

  get fileName(): string {
    return this._fileName
  }
  
  set fileName(fileName: string) {
    this._fileName = fileName
  }

  get sampleName(): string {
    return this._sampleName
  }
  
  set sampleName(sampleName: string) {
    this._sampleName = sampleName
  }

  get date(): Date {
    return this._date
  }
  
  set date(date: Date) {
    this._date = date
  }

  get status(): Status {
    return this._status
  }
  
  set status(status: Status) {
    this._status = status
  }

    get finalStatus(): FinalStatus {
        return this._finalStatus
    }

    set finalStatus(finalStatus: FinalStatus) {
        this._finalStatus = finalStatus
    }

  get file(): F | undefined {
    return this._file
  }
  
  set file(file: F | undefined) {
    this._file = file
  }

  get mutation(): string {
    return this._mutation
  }

  set mutation(mutation: string) {
    this._mutation = mutation
  }

  get baiFileID(): string {
    return this._baiFileID
  }
  
  set baiFileID(baiFileID: string) {
    this._baiFileID = baiFileID
  }


  get bimFileID(): string {
    return this._bimFileID
  }
  
  set bimFileID(bimFileID: string) {
    this._bimFileID = bimFileID
  }

  toDTO(): IndividualDTO {
    return {
      id: this.id,
      individual: this.individual,
      fileName: this.fileName,
      sampleName: this.sampleName,
      date: this.date,
      status: this.status,
        finalStatus: this.finalStatus,
      file: this.file,
      informativityID: this.informativityID,
      mutation: this.mutation,
      baiFileID: this.baiFileID,
      bimFileID: this.bimFileID
    }
  }
}

export function toModel(d: IndividualDTO): Individual {
  return new Individual(d)
}

export function emptyIndividualDTO(): IndividualDTO {
  return {
    id: uuidv4(),
    individual: Individuals.UNKNOWN,
    fileName: '-',
    sampleName: '-',
    date: new Date(),
    status: Status.Null,
    finalStatus: FinalStatus.Null,
    file: undefined,
    informativityID: '',
    mutation: '',
    baiFileID: '',
    bimFileID: ''
  }
}

export interface IndividualQuery {
}
