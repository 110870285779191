import { Container } from '../../common/container/Container'

export type IndividualContainerConfig = {
  moduleFullUrl: string
}

export type IndividualProps = {
  parentContainer: Container
  config: IndividualContainerConfig
}

export const INDIVIDUAL_API_KEY = Symbol('INDIVIDUAL_API_KEY')
export const INDIVIDUAL_SERVICE_KEY = Symbol('INDIVIDUAL_SERVICE_KEY')
export const INDIVIDUAL_MODULE = Symbol('INDIVIDUAL_MODULE')
