import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { StyleProps } from '../../components/entity-page/EntityPage'

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  root: (props) => ({
    width: '100%',
  }),
  title: (props) => ({
    color: props.color,
  }),
  titleModal: (props) => ({
    color: props.color,
    fontWeight: 'bold'
  }),
  container: {
    marginTop: theme.spacing(2),
  },
  stepTitle: {
    fontSize: 26,
    fontWeight: 1000,
    color: theme.palette.grey['400'],
  },
  stepSubtitle: {
    fontSize: 20,
    fontWeight: 800,
    color: theme.palette.grey['700'],
  },
  select: {
    background: theme.palette.common.white,
    '& .MuiOutlinedInput-input': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingRigth: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
  textInput: {
    '& .MuiOutlinedInput-input': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingRigth: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    background: theme.palette.common.white,
  },
  stepBox: {
    background: theme.palette.grey['300'],
    borderRadius: 10,
    padding: theme.spacing(3),
  },
  chipsBox: {
    minHeight: theme.spacing(6),
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    background: theme.palette.common.white,
  },
  button: {
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
    border: 0,
  },
  buttonSecondary: {
    color: theme.palette.common.white,
    background: theme.palette.grey['700'],
    border: 0,
  },
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    width: "500px",
    height: "350px",
    padding: 30,
    minWidth: 300,
    borderRadius: 8,
    paddingTop: 21,
  },
  buttonsModalContainer: {
    position: "absolute",
    bottom: 25,
    justifyContent: 'space-between', 
    display: 'flex',
    width: "440px"
  },
  switchControl: {
    display: 'flex', 
    justifyContent: 'center', 
    marginTop: '2%'
  },
  modalContainerNewUser: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    width: "500px",
    height: "550px",
    padding: 30,
    minWidth: 300,
    borderRadius: 8,
    paddingTop: 21,
  },
  modalContainerNewProject: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    width: "80%",
    height: "780px",
    padding: 30,
    minWidth: 300,
    borderRadius: 8,
    paddingTop: 21,
  },
  borderBoxProject: {
    border: '2px solid rgb(125, 191, 220)', 
    padding: 20, 
    width: '50%', 
    height: "360px"
  },
  borderBoxTitle: {
    position: 'absolute',
    top: -20,
    background: 'white',
    padding: '0 10px',
  },
  projectDate1: {
    width: '100%', 
    marginTop: '10%'
  },
  marginProject: {
    marginTop: '10%'
  },
  marginProject2: {
    marginTop: "3%"
  },
  row: {
    flexDirection: 'row', 
    display: 'flex'
  },
  containerButtonsFiles: {
    display: 'flex', 
    justifyContent: 'flex-end'
  },
  buttonUploadBedfile: {
    marginLeft: '6%', 
    width: "170px"
  },
  notDisplay: {
    display: 'none'
  },
  projectRightContainer: {
    width: '35%', 
    marginTop: '2%', 
    marginLeft: '3%'
  },
  projectSelectStyle: {
    textAlign: 'left', 
    marginTop: '1%'
  },
  projectDatePicker: {
    width: '100%', 
    marginTop: '6.5%'
  },
  projectName: {
    marginTop: '2%', 
    width: '40%'
  },
  leftProjectView: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: '5%',
  },
  marginUserTab: {
    marginTop: '7%'
  },
  scriptSelectGrid: {
    display: "flex", 
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: "1.5%"
  },
  scriptSelect: {
    textAlign: "initial", 
    marginTop: "0.5%"
  }
}))
