import { Observable } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { IAnalysisApi } from '../api/AnalysisApi'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { AnalysisDTO } from '../models/Analysis'
import { AnalysisInmonucompatibility, AnalysisInmonucompatibilityDTO } from '../models/AnalysisInmonucompatibility'

type Props = {
  apiKey: symbol
}

export interface IAnalysisService extends IInit {
   add(a: AnalysisDTO): Observable<any | undefined>
   addInmonucompatibility(a: AnalysisInmonucompatibilityDTO): Observable<AnalysisInmonucompatibility | undefined>
}

export class AnalysisService implements IAnalysisService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IAnalysisApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IAnalysisApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(a: AnalysisDTO): Observable<any | undefined> {
    return this._api.add(a)
  }

  addInmonucompatibility(a: AnalysisInmonucompatibilityDTO): Observable<any | undefined> {
    return this._api.addInmonucompatibility(a)
  }
}
