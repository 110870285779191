import {Container, ContainerItem} from "../common/container/Container";
import {UserProps} from "../modules/users/container";
import {WORD_API_KEY, WORD_SERVICE_KEY, WORDS_MODULE} from "../modules/word";
import {WordApi} from "../modules/word/api/WordApi";
import {WordService} from "../modules/word/services/WordService";
import {getAppContainer} from "./app";
import {moduleURLs} from "../config/api";


let container: Container

function init(p: UserProps) {
    const items = new Map<symbol, ContainerItem>()

    items.set(WORD_API_KEY, new ContainerItem({instance: new WordApi()}))
    items.set(
        WORD_SERVICE_KEY,
        new ContainerItem({
            instance: new WordService({apiKey: WORD_API_KEY}),
        })
    )

    container = new Container(items, p.parentContainer, p.config)
}

export function getWordContainer(): Container {
    if (!container) {
        let WORD_MODULE;
        init({
            parentContainer: getAppContainer(),
            config: {
                moduleFullUrl: moduleURLs[WORDS_MODULE],
            },
        })
    }

    return container
}