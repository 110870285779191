import {Container, IInit} from "../../../common/container/Container";
import {Observable, of} from "rxjs";
import {MutationsPosition, MutationsPositionDTO, toModel} from "../models/MutationsPosition";
import {HTTP_CLIENT_KEY, IHTTPClient} from "../../../common/api/HTTPClient";
import {IStatusService} from "../../../common/status/StatusService";
import {STATUS_SERVICE_KEY} from "../../../container/app";
import {MutationsPositionContainerConfig} from "../container";
import {catchError, map} from "rxjs/operators";
import {emptyList, ItemList} from "../../../common/models/ItemList";


export interface IMutationsPositionsApi extends IInit {
    getByID(id: string): Observable<ItemList<MutationsPosition>>
    add(e: MutationsPositionDTO): Observable<MutationsPosition | undefined>
    update(e: any): Observable<MutationsPosition | undefined>
    delete(id: string): Observable<boolean>

    updateMarkedPositions(e: any): Observable<boolean>;
}

export class MutationsPositionsApi implements IMutationsPositionsApi {
    private _container!: Container
    private _httpClient!: IHTTPClient
    private _url!: string
    private _statusService!: IStatusService

    init(c: Container) {
        this._container = c
        this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
        this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
        this._url = (this._container.config as MutationsPositionContainerConfig).moduleFullUrlMutationsPosition
    }

    add(e: MutationsPositionDTO): Observable<MutationsPosition | undefined> {
        return this._httpClient.post<MutationsPosition>({url: this._url, body: e}).pipe(
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(undefined)
            })
        )
    }

    delete(id: string): Observable<boolean> {
        return this._httpClient.delete({url: this._url + '/' + id}).pipe(
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(false)
            })
        )
    }

    getByID(id: string): Observable<ItemList<MutationsPosition>> {
        return this._httpClient.get<ItemList<MutationsPosition>>({url: `${this._url}/${id}`}).pipe(
            map<ItemList<MutationsPositionDTO>, ItemList<MutationsPosition>>((dto) => {
                const itemList = emptyList<MutationsPosition>()
                itemList.count = dto.count
                itemList.items = dto.items.map((d) => toModel(d))
                return itemList
            }),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(emptyList<MutationsPosition>())
            })
        )
    }

    update(e: any): Observable<MutationsPosition | undefined> {
        return this._httpClient.put<any>({url: this._url, body: e}).pipe(
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(undefined)
            })
        )
    }

    updateMarkedPositions(e: any): Observable<boolean> {
        return this._httpClient.put<any>({url: this._url + '/updateMarkedPositions', body: e}).pipe(
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(false)
            })
        )
    }
}
