import { TestFormPGD } from '../../features/pgdTests'
import { RouteProps } from '../../routes/AppRouter'
import { Title } from '../../components/common/Title'
import { TITLE_PATIENTS_PGD } from '../../routes/title-constants'
import { useTranslation } from 'react-i18next'

export const Form = (props: RouteProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Title title={t(TITLE_PATIENTS_PGD)} color={props.color} />
      <TestFormPGD />
    </>
  )
}
