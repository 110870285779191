import React, {CSSProperties} from 'react'
import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core'
import { Actions, Field, Sort } from './types'
import { makeStyles } from '@material-ui/core/styles'

type HeadProps<T> = {
  readonly fields: Field<T>[]
  readonly sort?: Sort<T>
  readonly actions?: Actions<T>
  readonly styleHeader?: CSSProperties
  readonly includesCheck?: boolean
}

export function Head<T extends { [key: string]: any }>(props: HeadProps<T>) {
  const handleSort = (name: keyof T) => () => props.sort?.handleSort && props.sort.handleSort(name)

  const useStyles = makeStyles((_) => ({
    head: {
      fontWeight: 'bold',
      backgroundColor: '#FFFFFF',
    },
  }))

  const classes = useStyles()

  const headCells = Object.values(props.fields).map((f) => (
    <TableCell style={props.styleHeader} className={classes.head} key={f.name as string}>
      {f.sortable ? (
        <TableSortLabel
          active={f.name === props.sort?.name}
          direction={props.sort?.direction || 'asc'}
          onClick={handleSort(f.name)}>
          {f.label}
        </TableSortLabel>
      ) : (
        f.label
      )}
    </TableCell>
  ))

  return (
    <TableHead>
      <TableRow>
      {props.includesCheck && (
          <TableCell />
        )}
        {headCells}
        {props.actions && props.actions.actionsColumn && (
          <TableCell style={props.styleHeader} className={classes.head}>{props.actions.actionsColumn}</TableCell>
        )}
      </TableRow>
    </TableHead>
  )
}
