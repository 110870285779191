import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { FormActions } from '../../common/utils/form-generation'
import { Box, Grid, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useForm } from '../../common/utils/form-generation/useForm'
import { emptyScriptDTO, ScriptDTO } from '../../modules/scripts/models/Script'
import { forkJoin, Observable } from 'rxjs'
import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { File as F, FileDTO, emptyFileDTO, toModel } from '../../modules/files/models/File'
import { getAuthContainer } from '../../container/auth-modules'
import { AuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import { v4 as uuidv4 } from 'uuid'
import { dataToBase64, downloadFile } from '../../common/files/file'
import { getScriptContainer } from '../../container/scripts-module'
import { ScriptService } from '../../modules/scripts/services/ScriptService'
import { SCRIPT_SERVICE_KEY } from '../../modules/scripts'
import { AppTable, Field } from '../../components/table'
import { map } from 'rxjs/operators'
import { Actions } from '../../components/table/types'
import downloadIcon from '../../assets/table-icons/download-icon.svg'
import uploadIcon from '../../assets/table-icons/upload-icon.svg'
import { Alert } from '@material-ui/lab'
import { InputLabel, MenuItem, Select } from '@material-ui/core'
import { useStyles } from '../matchings/Matching.styles'
import { COLOR_PRIMARY } from 'routes/color-constants'
import { Query, QueryParam } from 'common/api/Query'
import { useSnackbar } from 'notistack'
import seeIcon from '../../assets/table-icons/see-icon.svg'
import { navigate } from '@reach/router'
import { URL_BEDFILES } from 'routes/routes-constants'
import CircularProgress from '@material-ui/core/CircularProgress'

type FileType = {
  type: string
  file: F | undefined
}

const excludedKeysRecp = [
  'ABO_genotipado_ordena_alelos',
  'ABO_variants',
  'bam_check',
  'genotype_final',
  'HLAC',
  'KIR',
  'main',
  'output_joiner',
  'parse_v1',
  'rhd_auto',
  'rhd',
  'summary',
]

const fileService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)
const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)
const scriptService = getScriptContainer().get<ScriptService>(SCRIPT_SERVICE_KEY)

export const Form = () => {
  const { t } = useTranslation()
  const [isError, setIsError] = useState<boolean>(false)
  const [isErrorInm, setIsErrorInm] = useState<boolean>(false)
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [originalData, setOriginalData] = useState<ScriptDTO>(emptyScriptDTO('receptiveness'))
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isEditingInm, setIsEditingInm] = useState<boolean>(false)
  const [selectedFile, setSelectedFile] = useState<string>('')
  const [items, setItems] = useState<FileType[]>([])
  const [itemsinmunocompatibility, setItemsinmunocompatibility] = useState<FileType[]>([])
  const [itemsPGD, setItemsPGD] = useState<FileType[]>([])
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [project, setProject] = useState<string>('receptiveness')
  const options = [
    { value: 'pgd', label: 'PGD-SEQ' },
    { value: 'receptiveness', label: t('receptiveness') },
    { value: 'inmunocompatibility', label: t('inmunocompatibility') },
  ]
  const classes = useStyles({ color: COLOR_PRIMARY })
  const loggedUser = authService.get()
  const { enqueueSnackbar } = useSnackbar()
  const [isLoadingPdg, setIsLoadingPgd] = useState<boolean>(false)
  const [isLoadingInm, setIsLoadingInm] = useState<boolean>(true)
  const [isLoadingRecept, setIsLoadingRecept] = useState<boolean>(true)

  const getPGDFiles = async () => {
    let auxFiles: FileType[] = []

    let panel = await fileService
      .getPanelsAndBeds(
        new Query({
          query: [
            new QueryParam(
              'mimeType',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ),
            new QueryParam('ownerID', loggedUser.id),
          ],
        })
      )
      .toPromise()

    let panelFile = undefined
    if (panel && panel?.items.length > 0) {
      panelFile = panel?.items[0]
    }

    auxFiles.push({
      type: 'Panel',
      file: panelFile,
    })

    let bed = await fileService
      .getPanelsAndBeds(
        new Query({
          query: [new QueryParam('extension', '.bed'), new QueryParam('ownerID', loggedUser.id)],
        })
      )
      .toPromise()

    let bedFile = undefined
    if (bed && bed?.items.length > 0) {
      bedFile = bed?.items[0]
    }
    auxFiles.push({
      type: t('bedfiles'),
      file: bedFile,
    })

    setItemsPGD(auxFiles)
  }

  useEffect(() => {
    if (project != 'pgd') return
    getPGDFiles()
  }, [project])

  useEffect(() => {
    scriptService.getCurrentScript().subscribe((res) => {
      setIsEditing(!!res)
      if (!res) {
        return
      }
      const newData = res.toDTO()
      setData(newData)
      setOriginalData(newData)
      const entries = Object.entries(newData).filter((k) => k[0] != "id" && k[0] != "version" && !excludedKeysRecp.includes(k[0]))
      const values = entries.map((k) => k[1])
      const keys = entries.map((k) => k[0])
      getFiles(keys, values).subscribe((fl) => {
        setItems(fl)
        setIsLoadingRecept(false)
      })
    })
  }, [])

  useEffect(() => {
    scriptService.getCurrentScriptinmunocompatibility().subscribe((res) => {
      let auxInmFiles: FileType[] = []
      if (res?.items?.length == 0) {
        excludedKeysRecp.forEach((item) => {
          auxInmFiles.push({
            type: item,
            file: toModel(emptyFileDTO()),
          })
        })
      }

      res?.items.forEach((item) => {
        let types = excludedKeysRecp.filter((i) =>
          item.name.toLocaleLowerCase().includes(i.toLocaleLowerCase())
        )
        if (types.length > 0) {
          auxInmFiles.push({
            type: types[0],
            file: item,
          })
        }
      })

      if (res.items.length < excludedKeysRecp.length) {
        let notIncluded = excludedKeysRecp.filter((item) => !res.items.map((i)=> i.name.substring(0, i.name.lastIndexOf('.'))).includes(item) )
        notIncluded.length > 0 && auxInmFiles.push({
          type: notIncluded[0],
          file: undefined,
        })
      }

      setItemsinmunocompatibility(auxInmFiles)
      setIsLoadingInm(false)
      setIsEditingInm(!!res)
    })
  }, [])

  useEffect(() => {
    items.length && setIsError(items.some((i) => !i.file?.name?.toLowerCase().startsWith(i.type)))
  }, [items])

  useEffect(() => {
    itemsinmunocompatibility.length &&
      setIsErrorInm(
        itemsinmunocompatibility.some(
          (i) => !i.file?.name?.toLowerCase().startsWith(i.type.toLowerCase())
        )
      )
  }, [itemsinmunocompatibility])

  const handleFileInput = async (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    key: string
  ) => {
    setIsSuccess(false)
    const fl = (event.target as HTMLInputElement).files
    if (!fl?.length) {
      return
    }

    const index = items.findIndex((f) => f.type === key)
    if (index === -1) {
      return
    }
    const data = await dataToBase64(fl[0])
    const result = [...items]
    result.splice(index, 1, { type: key, file: new F(convertToFile(fl[0], data)) })
    setItems(result)
  }

  const handleFileInputInm = async (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    key: string
  ) => {
    setIsSuccess(false)
    const fl = (event.target as HTMLInputElement).files
    if (!fl?.length) {
      return
    }

    const index = itemsinmunocompatibility.findIndex((f) => f.type === key)
    if (index === -1) {
      return
    }

    const data = await dataToBase64(fl[0])
    const result = [...itemsinmunocompatibility]
    result.splice(index, 1, { type: key, file: new F(convertToFile(fl[0], data)) })
    setItemsinmunocompatibility(result)
  }

  const handleUploadPanelFile = async (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const files = (event.target as HTMLInputElement).files
    const file = files && files.length && files[0]

    if (file) {
      if (file.name.substring(file.name.length - 4, file.name.length) !== 'xlsx') {
        enqueueSnackbar(t('fileMustHaveXLSXExtension'), { variant: 'error' })
        return
      }

      const fileData = await dataToBase64(file)
      fileService
        .uploadPanelFile({
          id: uuidv4(),
          name: file.name,
          data: fileData,
          size: file.size,
          mimeType: file.type,
          extension: file.type.split('/')[1],
          ownerID: loggedUser.id,
        })
        .subscribe(() => {
          getPGDFiles()
        })
    }
  }

  const handleUploadBedFile = async (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const files = (event.target as HTMLInputElement).files
    const file = files && files.length && files[0]
    if (file) {
      if (file.name.substring(file.name.length - 3, file.name.length) !== 'bed') {
        enqueueSnackbar(t('fileMustHaveBedExtension'), { variant: 'error' })
        return
      }
      const fileData = await dataToBase64(file)

      setIsLoadingPgd(true)

      fileService
        .uploadBedFile({
          id: uuidv4(),
          name: file.name,
          data: fileData,
          size: file.size,
          mimeType: file.type,
          extension: file.type.split('/')[1],
          ownerID: loggedUser.id,
        })
        .subscribe(() => {
          setIsLoadingPgd(false)
          getPGDFiles()
        })
    }
  }

  const handleFileInputPGD = async (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    key: string
  ) => {
    if (key == 'Panel') {
      handleUploadPanelFile(event)
    } else if (key == t('bedfiles')) {
      handleUploadBedFile(event)
    }
  }

  const convertToFile = (f: File, data: string): FileDTO => ({
    id: uuidv4(),
    name: f.name,
    data,
    size: f.size,
    mimeType: f.type,
    extension: f.type.split('/')[1],
    ownerID: authService.get().id,
  })

  const getFiles = (keys: string[], ids: string[]): Observable<FileType[]> =>
    forkJoin(
      ids.map((id, i) =>
        fileService.getCurrentScriptByID(id).pipe(
          map((f) => {
            return {
              type: keys[i],
              file: f,
            }
          })
        )
      )
    ) as unknown as Observable<FileType[]>

  const addFiles = (fl: FileDTO[]): Observable<F[]> =>
    forkJoin(fl.map((f) => fileService.add(f))) as unknown as Observable<F[]>

  const addFilesInm = (fl: FileDTO[]): Observable<F[]> =>
    forkJoin(fl.map((f) => fileService.addInm(f))) as unknown as Observable<F[]>

  const deleteFiles = (ids: string[]): Observable<boolean[]> =>
    forkJoin(ids.map((id) => fileService.delete(id))) as unknown as Observable<boolean[]>

  const changeFile = (f: FileType) => {
    setSelectedFile(f.type)
    fileInputRef.current?.click()
  }

  const download = (f: FileType) =>
    f.file && downloadFile(f.file.name, f.file.mimeType, f.file.data)

  const hideFile = (f: FileType) => !f.file?.name

  const { handleChange, handleSubmit, data, setData, errors } = useForm<ScriptDTO>({
    onSubmit: () => {
      const fl: FileDTO[] = []
      items.forEach((i) => i.file && fl.push(i.file.toDTO()))
      if (!fl.length) {
        return
      }
      addFiles(fl).subscribe(() => {
        const newData: ScriptDTO = Object.assign(
          { ...data },
          ...items.map((i) => ({ [i.type]: i.file?.id }))
        )

        setIsSuccess(true)
        if (!isEditing) {
          scriptService.create(newData).subscribe(() => setIsEditing(true))
          return
        }
        const oldIDs = Object.entries(originalData)
          .filter((k) => !excludedKeysRecp.includes(k[0]))
          .map((v) => v[1])
        const newIDs = items.map((i) => i.file?.id)
        scriptService
          .update(newData)
          .subscribe(() =>
            deleteFiles(oldIDs.filter((o) => newIDs.every((n) => n !== o))).subscribe()
          )
      })
    },

    initialValues: emptyScriptDTO('receptiveness'),
  })

  const handleSubmitInm = (e: any) => {
    e.preventDefault()
    const fl: FileDTO[] = []
    itemsinmunocompatibility.forEach((i) => i.file && fl.push(i.file.toDTO()))
    if (!fl.length) {
      return
    }

    addFilesInm(fl).subscribe(() => {
      setIsSuccess(true)
    })
  }

  const fields: Field<FileType>[] = [
    {
      label: t('file'),
      name: 'type',
      renderFunc: (f, i) => t(i.type),
    },
    {
      label: t('name'),
      name: 'file',
      renderFunc: (f, i) => i.file?.name || '',
    },
  ]

  const actions: Actions<FileType> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: changeFile,
        icon: uploadIcon,
        label: t('Upload'),
      },
      {
        handler: download,
        icon: downloadIcon,
        label: t('Download'),
        hidden: hideFile,
      },
    ],
  }

  const fieldsPGD: Field<FileType>[] = [
    {
      label: t('file'),
      name: 'type',
      renderFunc: (f, i) => t(i.type),
    },
    {
      label: t('name'),
      name: 'file',
      renderFunc: (f, i) => (i.type == 'Panel' ? i.file?.name || '' : ''),
    },
  ]

  const actionsPGD: Actions<FileType> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: changeFile,
        icon: uploadIcon,
        label: t('Upload'),
      },
      {
        handler: () => navigate(URL_BEDFILES),
        icon: seeIcon,
        label: t('see'),
        hidden: (i) => i.type == 'Panel',
      },
      {
        handler: download,
        icon: downloadIcon,
        label: t('Download'),
        hidden: (i) => hideFile(i) || i.type != 'Panel',
      },
    ],
  }

  return (
    <form onSubmit={project == 'receptiveness' ? handleSubmit : handleSubmitInm}>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.scriptSelectGrid}>
          <InputLabel>{t('project')}</InputLabel>
          <Select
            fullWidth
            variant="outlined"
            value={project}
            className={classes.scriptSelect}
            onChange={(e) => e && typeof e.target.value === 'string' && setProject(e.target.value)}>
            {options.map((l) => (
              <MenuItem value={l.value} key={l.value}>
                {l.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {project == 'receptiveness' && (
          <>
            {!isLoadingRecept ? (
              <>
                <Grid item xs={12}>
                  <TextField
                    error={errors['version'] !== undefined}
                    fullWidth
                    variant={'outlined'}
                    id="version"
                    type={'number'}
                    label={t('version')}
                    onChange={(event) => handleChange('version', +event.target.value)}
                    value={data && data.version}
                    helperText={errors['version']}
                    InputProps={{ inputProps: { min: 0, step: 0.01 } }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <AppTable fields={fields} items={items} rowKeyField={'type'} actions={actions} />
                <input
                  onChange={(event) => handleFileInput(event, selectedFile)}
                  multiple={false}
                  ref={fileInputRef}
                  type="file"
                  hidden
                />
              </>
            ) : (
              <Box style={{ width: '100%', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            )}
          </>
        )}

        {project == 'pgd' && (
          <>
            {!isLoadingPdg ? (
              <>
                <AppTable
                  fields={fieldsPGD}
                  items={itemsPGD}
                  rowKeyField={'type'}
                  actions={actionsPGD}
                />
                <input
                  onChange={(event) => handleFileInputPGD(event, selectedFile)}
                  multiple={false}
                  ref={fileInputRef}
                  type="file"
                  hidden
                />
              </>
            ) : (
              <div style={{ width: '100%' }}>
                <CircularProgress style={{ alignSelf: 'center' }} />
              </div>
            )}
          </>
        )}

        {project == 'inmunocompatibility' && (
          <>
            {!isLoadingInm ? (
              <>
                <AppTable
                  fields={fields}
                  items={itemsinmunocompatibility}
                  rowKeyField={'type'}
                  actions={actions}
                />
                <input
                  onChange={(event) => handleFileInputInm(event, selectedFile)}
                  multiple={false}
                  ref={fileInputRef}
                  type="file"
                  hidden
                />
              </>
            ) : (
              <Box style={{ width: '100%', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            )}
          </>
        )}
      </Grid>

      {isError && project == 'receptiveness' && (
        <Box mt={3}>
          <Alert severity="error">
            {' '}
            {t('someFilesError')}:{' '}
            {items
              .filter((i) => !i.file?.name.toLowerCase().startsWith(i.type))
              .map((i) => i.type)
              .join(', ')}{' '}
          </Alert>
        </Box>
      )}
      {isErrorInm && project == 'inmunocompatibility' && (
        <Box mt={3}>
          <Alert severity="error">
            {' '}
            {t('someFilesError')}:{' '}
            {itemsinmunocompatibility
              .filter((i) => {
                return !i.file?.name.toLowerCase().startsWith(i.type.toLocaleLowerCase())
              })
              .map((i) => i.type)
              .join(', ')}{' '}
          </Alert>
        </Box>
      )}
      {isSuccess && (
        <Box mt={3}>
          <Alert severity="success">{t('allChangesSaved')}</Alert>
        </Box>
      )}
      {project == 'receptiveness' && <FormActions disabled={isError} />}
      {project == 'inmunocompatibility' && <FormActions disabled={isErrorInm} />}
    </form>
  )
}
