import { RouteProps } from '../../routes/AppRouter'
import { Detail as PatientsDetail } from '../../features/patients/Detail'
import { Title } from 'components/common/Title'

export function Detail(props: RouteProps) {
  return (
    <>
      <Title color={props.color} title={props.title || ''} />
      <PatientsDetail id={props.id} />
    </>
  )
}
