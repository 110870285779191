import { Container } from '../../common/container/Container'

export type WordContainerConfig = {
    moduleFullUrl: string
}

export type WordProps = {
    parentContainer: Container
    config: WordContainerConfig
}

export const WORD_API_KEY = Symbol('WORD_API_KEY')
export const WORD_SERVICE_KEY = Symbol('WORD_SERVICE_KEY')
export const WORDS_MODULE = Symbol('WORDS_MODULE')