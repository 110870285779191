import { RouteProps } from '../../routes/AppRouter'
import { PatientsTable } from '../../features/patients'
import { Title } from '../../components/common/Title'

export const Table = (props: RouteProps) => {
  return (
    <>
      <Title color={props.color} title={props.title || ''} />
      <PatientsTable />
    </>
  )
}
