import { Box, Grid, Modal, Step, StepLabel, Stepper } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import React, { FormEvent, useState } from 'react'
import { getAuthContainer } from '../../container/auth-modules'
import { AuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import { ResultPatientDataDTO } from '../../modules/script-executions/models/ResultPatientData'
import { FirstStep } from './FirstStep'
import { forkJoin } from 'rxjs'
import { getFileContainer } from '../../container/file-module'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { FileService } from '../../modules/files/services/FileService'
import { SecondStep } from './SecondStep'
import { FormAction, FormActions } from '../../common/utils/form-generation'
import { commonStyles } from '../../common/styles/Styles'
import { AnalysisInmonucompatibility } from 'modules/analysis/models/AnalysisInmonucompatibility'

const steps = ['checks', 'analysis']

export type FileType = {
  id: string
  name: string
}

const fileService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)

export const Form = () => {
  const styles = commonStyles()
  const { t } = useTranslation()
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [resultPatientList, setResultPatientList] = useState<ResultPatientDataDTO[]>([])
  const [fileTypes, setFileTypes] = useState<FileType[]>([])
  const [selectedClinic, setSelectedClinic] = useState<string>('')
  const [open, setOpen] = useState<boolean>(false)

  const increaseStep = () => {
    setCurrentStep(currentStep + 1)
}
  const deleteFiles = (e: FormEvent) => {
    e.preventDefault()
    forkJoin(fileTypes.map((f) => fileService.delete(f.id))).subscribe(() => {
      setOpen(false)
      setCurrentStep(0)
    })
  }
  const actionsDelete: FormAction[] = [
    {
      label: t('close'),
      handleAction: () => setOpen(false),
    },
  ]

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stepper activeStep={currentStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{t(label)}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>

      {currentStep === 0 && (
        <FirstStep
          increaseStep={increaseStep}
          files={setFileTypes}
          valuesToTable={setResultPatientList}
          selectedClinic={setSelectedClinic}
        />
      )}

      {currentStep === 1 && (
        <SecondStep
          analysis={
            new AnalysisInmonucompatibility({
              summaryID: fileTypes.find((f) => f.name.endsWith('bc_summary.xls'))?.id || '',
              sequenceID: fileTypes.find((f) => f.name.endsWith('.fastq'))?.id || '',
              alignmentID: fileTypes.find((f) => f.name.includes('.bam'))?.id || '',
              cobertureID: fileTypes.find((f) => f.name.endsWith('.xls') && !f.name.endsWith('bc_summary.xls'))?.id || '',
              zipFileID: undefined,
              clinicID: selectedClinic,
              patients: resultPatientList,
            })
          }
          resetSteps={() => setCurrentStep(0)}
        />
      )}

      <Modal open={open}>
        <Box className={styles.modal}>
          <form onSubmit={deleteFiles}>
            <Box mt={2} textAlign={'justify'}>
              {t('cancelinmunocompatibility')}
            </Box>
            <FormActions actions={actionsDelete} message={t('confirm')} />
          </form>
        </Box>
      </Modal>
    </Grid>
  )
}
