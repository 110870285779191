import { Container } from '../../common/container/Container'

export type PatientContainerConfig = {
  moduleFullUrlPatient: string
  moduleFullUrlPatientParams: string
}

export type PatientProps = {
  parentContainer: Container
  config: PatientContainerConfig
}

export const PATIENT_API_KEY = Symbol('PATIENT_API_KEY')
export const PATIENT_SERVICE_KEY = Symbol('PATIENT_SERVICE_KEY')
export const PATIENTS_MODULE = Symbol('PATIENTS_MODULE')

export const PATIENTS_PGD_MODULE = Symbol('PATIENTS_PGD_MODULE')

export const PATIENT_PARAM_API_KEY = Symbol('PATIENT_PARAM_API_KEY')
export const PATIENT_PARAM_SERVICE_KEY = Symbol('PATIENT_PARAM_SERVICE_KEY')
export const PATIENT_PARAMS_MODULE = Symbol('PATIENT_PARAMS_MODULE')
