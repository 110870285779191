import {ProjectAnalysis} from "../../modules/projects/models/Project";
import {Button} from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import React from "react";
import {commonStyles} from "../../common/styles/Styles";
import {useTranslation} from "react-i18next";


type SecondStepProps = {
    increaseStep?: () => void
    deleteFiles?: () => void
    decreaseStep?: () => void
    project?: ProjectAnalysis
}

export const FourthStep = (props: SecondStepProps) => {

    const styles2 = commonStyles()
    const {t} = useTranslation()

    return (
        <>
            <Button
                variant="contained"
                startIcon={<ArrowBack/>}
                onClick={props.decreaseStep}
                className={styles2.button}
                style={{display: 'flex', alignSelf: 'flex-start'}}>
                Atrás
            </Button>
            En desarrollo...
        </>
    )
}