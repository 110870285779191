import {RouteProps} from '../../routes/AppRouter'
import {getTestContainer} from '../../container/test-module'
import {TestService} from '../../modules/tests/services/TestService'
import {TEST_SERVICE_KEY} from '../../modules/tests'
import {Test, TestDTO} from '../../modules/tests/models/Test'
import {getPatientContainer} from '../../container/patient-module'
import {PatientService} from '../../modules/patients/services/PatientService'
import {PATIENT_SERVICE_KEY} from '../../modules/patients'
import {FormAction, FormActions} from '../../common/utils/form-generation'
import {Grid, TextField} from '@material-ui/core'
import {navigate} from '@reach/router'
import {ChangeEvent, useEffect, useState} from 'react'
import {URL_PATIENT_SHOW} from '../../routes/routes-constants'
import {useForm} from '../../common/utils/form-generation/useForm'
import {PatientReceptiveness} from '../../modules/patients/models/PatientReceptiveness'
import {useTranslation} from 'react-i18next'
import {v4 as uuidv4} from 'uuid'
import {dataToBase64} from '../../common/files/file'
import {getAuthContainer} from '../../container/auth-modules'
import {IAuthService} from '../../modules/auth/services/AuthService'
import {AUTH_SERVICE_KEY} from '../../modules/auth'
import {getReceptivenessFileContainer} from '../../container/receptiveness-files-module'
import {ReceptivenessFileService} from '../../modules/receptiveness-files/services/ReceptivenessFileService'
import {RECEPTIVENESS_FILE_SERVICE_KEY} from '../../modules/receptiveness-files/container'

const patientsService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)
const testsService = getTestContainer().get<TestService>(TEST_SERVICE_KEY)
const authService = getAuthContainer().get<IAuthService>(AUTH_SERVICE_KEY)
const receptivenessFileService = getReceptivenessFileContainer().get<ReceptivenessFileService>(RECEPTIVENESS_FILE_SERVICE_KEY)

export type FormProps = RouteProps & {
    test: Test | undefined
}

export const Form = (props: FormProps) => {
    const {t} = useTranslation()
    const [selectedFile, setSelectedFile] = useState<File>()
    const [patient, setPatient] = useState<PatientReceptiveness>()

    useEffect(() => {
        props.test && setData(props.test.toDTO())
    }, [props.test])

    const {handleChange, handleSubmit, data, setData, errors} = useForm<TestDTO>({
        validations: {
            patientID: {
                required: {
                    value: true,
                    message: t('patientNotValidError'),
                },
            },
            testTypeID: {
                required: {
                    value: true,
                    message: t('testTypeNotValidError'),
                },
            },
        },

        onSubmit: () => {
            if (!data.id || data.id === '') {
                const testID = uuidv4()
                testsService.add({...data, id: testID, createdAt: new Date()}).subscribe(async () => {
                    if (selectedFile) {
                        const id = uuidv4()
                        const fileData = await dataToBase64(selectedFile)
                        receptivenessFileService.add({
                            id,
                            name: selectedFile.name,
                            data: fileData,
                            size: selectedFile.size,
                            mimeType: selectedFile.type,
                            extension: selectedFile.type.split('/')[1],
                            ownerID: authService.get().id,
                            zipFileID: id,
                        }).subscribe(() => {
                            testsService.update({...data, id: testID, pdf: id}).subscribe()
                        })
                    }
                    setTimeout(() => goToPatientDetail(), 1000)
                })
            }
        },

        initialValues: props.test && props.test.toDTO(),
    })

    useEffect(() => {
        if (!data.patientID) {
            return
        }
        patientsService.getByID(data.patientID)
            .subscribe((res) => setPatient(res))
    }, [data])


    const handleFileInput = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const files = (event.target as HTMLInputElement).files
        files && files.length > 0 && setSelectedFile(files[0])
    }

    const goToPatientDetail = () => navigate(URL_PATIENT_SHOW.replace(':id', data.patientID))

    const actions: FormAction[] = [
        {
            label: t('back'),
            handleAction: () => goToPatientDetail(),
        },
    ]

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant={'outlined'}
                        id={'patientID'}
                        type={'string'}
                        onChange={() => {
                        }}
                        value={(patient && patient.fullName) || ''}
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant={'outlined'}
                        id='pdfFile'
                        onChange={(event) => handleFileInput(event)}
                        type={'file'}
                        label={t('pdfFile')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
            </Grid>
            <FormActions actions={actions}/>
        </form>
    )
}
