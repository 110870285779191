import i18n from '../../../i18n/i18n'
import { Option, OptionValue } from '../../../common/utils/form-generation/types'

export enum GenderType {
  Male = 1,
  Female,
}

export const userGenders = (): Record<GenderType, string> => ({
  [GenderType.Male]: i18n.t('Male'),
  [GenderType.Female]: i18n.t('Female'),
})

export const optionUserGenders = (): Option<OptionValue>[] => [
  { value: GenderType.Male, label: 'Male' },
  { value: GenderType.Female, label: 'Female' },
]


export enum ChildGenderPGD {
  Male = 1,
  Female,
  Unknown,
}

export const childGendersPGD = (): Record<ChildGenderPGD, string> => ({
    [ChildGenderPGD.Male]: i18n.t('Male'),
    [ChildGenderPGD.Female]: i18n.t('Female'),
    [ChildGenderPGD.Unknown]: i18n.t('Unknown'),
})