import { Container } from '../../common/container/Container'

export type ProjectContainerConfig = {
  moduleFullUrl: string
}

export type ProjectProps = {
  parentContainer: Container
  config: ProjectContainerConfig
}

export const PROJECT_API_KEY = Symbol('PROJECT_API_KEY')
export const PROJECT_SERVICE_KEY = Symbol('PROJECT_SERVICE_KEY')
export const PROJECT_MODULE = Symbol('PROJECT_MODULE')
